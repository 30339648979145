
import { route } from 'quasar/wrappers'
import { createRouter, createMemoryHistory, createWebHistory, createWebHashHistory } from 'vue-router'

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation;
 *
 * The function below can be async too; either use
 * async/await or return a Promise which resolves
 * with the Router instance.
 */


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [{
	name: 'main',
	path: '/',
	component: () => import('layouts/MainLayout.vue'),
	children: [
		
		{ 
			path: '/home',
			name: 'home', 
			component: () => import('pages/home/home.vue'), 
			props: true 
		},//Dashboard routes


//all_about_users routes
		{
			path: '/all_about_users/:fieldName?/:fieldValue?',
			name: 'all_about_userslist',
			component: () => import('pages/all_about_users/list.vue'),
			props: route => passRouteToProps(route)
		},

//all_users routes
		{
			path: '/all_users/:fieldName?/:fieldValue?',
			name: 'all_userslist',
			component: () => import('pages/all_users/list.vue'),
			props: route => passRouteToProps(route)
		},

//allaboutusers routes

//allusers routes

//app_versions routes
		{
			path: '/app_versions/:fieldName?/:fieldValue?',
			name: 'app_versionslist',
			component: () => import('pages/app_versions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/app_versions/view/:id', 
			name: 'app_versionsview', 
			component: () => import('pages/app_versions/view.vue'), 
			props: true 
		},
		{ 
			path: '/app_versions/add', 
			name: 'app_versionsadd', 
			component: () => import('pages/app_versions/add.vue'), 
			props: true 
		},
		{ 
			path: '/app_versions/edit/:id', 
			name: 'app_versionsedit', 
			component: () => import('pages/app_versions/edit.vue'), 
			props: true 
		},

//appversions routes

//bank routes

//broadcasts routes
		{
			path: '/broadcasts/:fieldName?/:fieldValue?',
			name: 'broadcastslist',
			component: () => import('pages/broadcasts/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/broadcasts/view/:id', 
			name: 'broadcastsview', 
			component: () => import('pages/broadcasts/view.vue'), 
			props: true 
		},
		{ 
			path: '/broadcasts/add', 
			name: 'broadcastsadd', 
			component: () => import('pages/broadcasts/add.vue'), 
			props: true 
		},
		{ 
			path: '/broadcasts/edit/:id', 
			name: 'broadcastsedit', 
			component: () => import('pages/broadcasts/edit.vue'), 
			props: true 
		},

//bundling_orders routes
		{
			path: '/bundling_orders/:fieldName?/:fieldValue?',
			name: 'bundling_orderslist',
			component: () => import('pages/bundling_orders/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/bundling_orders/view/:id', 
			name: 'bundling_ordersview', 
			component: () => import('pages/bundling_orders/view.vue'), 
			props: true 
		},
		{ 
			path: '/bundling_orders/add', 
			name: 'bundling_ordersadd', 
			component: () => import('pages/bundling_orders/add.vue'), 
			props: true 
		},
		{ 
			path: '/bundling_orders/edit/:id', 
			name: 'bundling_ordersedit', 
			component: () => import('pages/bundling_orders/edit.vue'), 
			props: true 
		},

//bundlings routes
		{
			path: '/bundlings/:fieldName?/:fieldValue?',
			name: 'bundlingslist',
			component: () => import('pages/bundlings/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/bundlings/view/:id', 
			name: 'bundlingsview', 
			component: () => import('pages/bundlings/view.vue'), 
			props: true 
		},
		{ 
			path: '/bundlings/add', 
			name: 'bundlingsadd', 
			component: () => import('pages/bundlings/add.vue'), 
			props: true 
		},
		{ 
			path: '/bundlings/edit/:id', 
			name: 'bundlingsedit', 
			component: () => import('pages/bundlings/edit.vue'), 
			props: true 
		},

//calender_event_categories routes
		{
			path: '/calender_event_categories/:fieldName?/:fieldValue?',
			name: 'calender_event_categorieslist',
			component: () => import('pages/calender_event_categories/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/calender_event_categories/view/:id', 
			name: 'calender_event_categoriesview', 
			component: () => import('pages/calender_event_categories/view.vue'), 
			props: true 
		},
		{ 
			path: '/calender_event_categories/add', 
			name: 'calender_event_categoriesadd', 
			component: () => import('pages/calender_event_categories/add.vue'), 
			props: true 
		},
		{ 
			path: '/calender_event_categories/edit/:id', 
			name: 'calender_event_categoriesedit', 
			component: () => import('pages/calender_event_categories/edit.vue'), 
			props: true 
		},

//calender_events routes
		{
			path: '/calender_events/:fieldName?/:fieldValue?',
			name: 'calender_eventslist',
			component: () => import('pages/calender_events/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/calender_events/view/:id', 
			name: 'calender_eventsview', 
			component: () => import('pages/calender_events/view.vue'), 
			props: true 
		},
		{ 
			path: '/calender_events/add', 
			name: 'calender_eventsadd', 
			component: () => import('pages/calender_events/add.vue'), 
			props: true 
		},
		{ 
			path: '/calender_events/edit/:id', 
			name: 'calender_eventsedit', 
			component: () => import('pages/calender_events/edit.vue'), 
			props: true 
		},

//card_logs routes
		{
			path: '/card_logs/:fieldName?/:fieldValue?',
			name: 'card_logslist',
			component: () => import('pages/card_logs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/card_logs/view/:id', 
			name: 'card_logsview', 
			component: () => import('pages/card_logs/view.vue'), 
			props: true 
		},
		{ 
			path: '/card_logs/add', 
			name: 'card_logsadd', 
			component: () => import('pages/card_logs/add.vue'), 
			props: true 
		},
		{ 
			path: '/card_logs/edit/:id', 
			name: 'card_logsedit', 
			component: () => import('pages/card_logs/edit.vue'), 
			props: true 
		},

//cardlogs routes

//cards routes

//category routes

//cek_club_propinsi routes
		{
			path: '/cek_club_propinsi/:fieldName?/:fieldValue?',
			name: 'cek_club_propinsilist',
			component: () => import('pages/cek_club_propinsi/list.vue'),
			props: route => passRouteToProps(route)
		},

//cek_duplicate_club routes
		{
			path: '/cek_duplicate_club/:fieldName?/:fieldValue?',
			name: 'cek_duplicate_clublist',
			component: () => import('pages/cek_duplicate_club/list.vue'),
			props: route => passRouteToProps(route)
		},

//cek_kta_agnes routes
		{
			path: '/cek_kta_agnes/:fieldName?/:fieldValue?',
			name: 'cek_kta_agneslist',
			component: () => import('pages/cek_kta_agnes/list.vue'),
			props: route => passRouteToProps(route)
		},

//cek_kta_crm routes
		{
			path: '/cek_kta_crm/:fieldName?/:fieldValue?',
			name: 'cek_kta_crmlist',
			component: () => import('pages/cek_kta_crm/list.vue'),
			props: route => passRouteToProps(route)
		},

//cek_kta_vs_club routes
		{
			path: '/cek_kta_vs_club/:fieldName?/:fieldValue?',
			name: 'cek_kta_vs_clublist',
			component: () => import('pages/cek_kta_vs_club/list.vue'),
			props: route => passRouteToProps(route)
		},

//cek_status_kta routes
		{
			path: '/cek_status_kta/:fieldName?/:fieldValue?',
			name: 'cek_status_ktalist',
			component: () => import('pages/cek_status_kta/list.vue'),
			props: route => passRouteToProps(route)
		},

//cek_useer_double_club routes
		{
			path: '/cek_useer_double_club/:fieldName?/:fieldValue?',
			name: 'cek_useer_double_clublist',
			component: () => import('pages/cek_useer_double_club/list.vue'),
			props: route => passRouteToProps(route)
		},

//cekclubpropinsi routes

//cekktaagnes routes

//cekktacrm routes

//cekstatuskta routes
		{
			path: '/cekstatuskta/:fieldName?/:fieldValue?',
			name: 'cekstatusktalist',
			component: () => import('pages/cekstatuskta/list.vue'),
			props: route => passRouteToProps(route)
		},

//change_logs routes
		{
			path: '/change_logs/:fieldName?/:fieldValue?',
			name: 'change_logslist',
			component: () => import('pages/change_logs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/change_logs/view/:id', 
			name: 'change_logsview', 
			component: () => import('pages/change_logs/view.vue'), 
			props: true 
		},
		{ 
			path: '/change_logs/add', 
			name: 'change_logsadd', 
			component: () => import('pages/change_logs/add.vue'), 
			props: true 
		},
		{ 
			path: '/change_logs/edit/:id', 
			name: 'change_logsedit', 
			component: () => import('pages/change_logs/edit.vue'), 
			props: true 
		},

//cities routes

//clogs routes

//club_claim routes
		{
			path: '/club_claim/:fieldName?/:fieldValue?',
			name: 'club_claimlist',
			component: () => import('pages/club_claim/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_claim/view/:id', 
			name: 'club_claimview', 
			component: () => import('pages/club_claim/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_claim/add', 
			name: 'club_claimadd', 
			component: () => import('pages/club_claim/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_claim/edit/:id', 
			name: 'club_claimedit', 
			component: () => import('pages/club_claim/edit.vue'), 
			props: true 
		},

//club_details routes
		{
			path: '/club_details/:fieldName?/:fieldValue?',
			name: 'club_detailslist',
			component: () => import('pages/club_details/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_details/view/:id', 
			name: 'club_detailsview', 
			component: () => import('pages/club_details/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_details/add', 
			name: 'club_detailsadd', 
			component: () => import('pages/club_details/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_details/edit/:id', 
			name: 'club_detailsedit', 
			component: () => import('pages/club_details/edit.vue'), 
			props: true 
		},

//club_members routes
		{
			path: '/club_members/:fieldName?/:fieldValue?',
			name: 'club_memberslist',
			component: () => import('pages/club_members/list.vue'),
			props: route => passRouteToProps(route)
		},

//club_membership routes
		{
			path: '/club_membership/:fieldName?/:fieldValue?',
			name: 'club_membershiplist',
			component: () => import('pages/club_membership/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_membership/view/:id', 
			name: 'club_membershipview', 
			component: () => import('pages/club_membership/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_membership/add', 
			name: 'club_membershipadd', 
			component: () => import('pages/club_membership/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_membership/edit/:id', 
			name: 'club_membershipedit', 
			component: () => import('pages/club_membership/edit.vue'), 
			props: true 
		},

//club_membership_packages routes
		{
			path: '/club_membership_packages/:fieldName?/:fieldValue?',
			name: 'club_membership_packageslist',
			component: () => import('pages/club_membership_packages/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_membership_packages/view/:id', 
			name: 'club_membership_packagesview', 
			component: () => import('pages/club_membership_packages/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_membership_packages/add', 
			name: 'club_membership_packagesadd', 
			component: () => import('pages/club_membership_packages/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_membership_packages/edit/:id', 
			name: 'club_membership_packagesedit', 
			component: () => import('pages/club_membership_packages/edit.vue'), 
			props: true 
		},

//club_roles routes
		{
			path: '/club_roles/:fieldName?/:fieldValue?',
			name: 'club_roleslist',
			component: () => import('pages/club_roles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_roles/view/:id', 
			name: 'club_rolesview', 
			component: () => import('pages/club_roles/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_roles/add', 
			name: 'club_rolesadd', 
			component: () => import('pages/club_roles/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_roles/edit/:id', 
			name: 'club_rolesedit', 
			component: () => import('pages/club_roles/edit.vue'), 
			props: true 
		},

//club_roles_20230913 routes
		{
			path: '/club_roles_20230913/:fieldName?/:fieldValue?',
			name: 'club_roles_20230913list',
			component: () => import('pages/club_roles_20230913/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_roles_20230913/view/:id', 
			name: 'club_roles_20230913view', 
			component: () => import('pages/club_roles_20230913/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_roles_20230913/add', 
			name: 'club_roles_20230913add', 
			component: () => import('pages/club_roles_20230913/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_roles_20230913/edit/:id', 
			name: 'club_roles_20230913edit', 
			component: () => import('pages/club_roles_20230913/edit.vue'), 
			props: true 
		},

//club_user_requests routes
		{
			path: '/club_user_requests/:fieldName?/:fieldValue?',
			name: 'club_user_requestslist',
			component: () => import('pages/club_user_requests/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_user_requests/view/:id', 
			name: 'club_user_requestsview', 
			component: () => import('pages/club_user_requests/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_user_requests/add', 
			name: 'club_user_requestsadd', 
			component: () => import('pages/club_user_requests/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_user_requests/edit/:id', 
			name: 'club_user_requestsedit', 
			component: () => import('pages/club_user_requests/edit.vue'), 
			props: true 
		},

//club_users routes
		{
			path: '/club_users/:fieldName?/:fieldValue?',
			name: 'club_userslist',
			component: () => import('pages/club_users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users/view/:id', 
			name: 'club_usersview', 
			component: () => import('pages/club_users/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users/add', 
			name: 'club_usersadd', 
			component: () => import('pages/club_users/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users/edit/:id', 
			name: 'club_usersedit', 
			component: () => import('pages/club_users/edit.vue'), 
			props: true 
		},

//club_users_2023_06023 routes
		{
			path: '/club_users_2023_06023/:fieldName?/:fieldValue?',
			name: 'club_users_2023_06023list',
			component: () => import('pages/club_users_2023_06023/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2023_06023/view/:id', 
			name: 'club_users_2023_06023view', 
			component: () => import('pages/club_users_2023_06023/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_06023/add', 
			name: 'club_users_2023_06023add', 
			component: () => import('pages/club_users_2023_06023/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_06023/edit/:id', 
			name: 'club_users_2023_06023edit', 
			component: () => import('pages/club_users_2023_06023/edit.vue'), 
			props: true 
		},

//club_users_2023_06023 routes
		{
			path: '/club_users_2023_06023/:fieldName?/:fieldValue?',
			name: 'club_users_2023_06023list',
			component: () => import('pages/club_users_2023_06023/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2023_06023/view/:id', 
			name: 'club_users_2023_06023view', 
			component: () => import('pages/club_users_2023_06023/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_06023/add', 
			name: 'club_users_2023_06023add', 
			component: () => import('pages/club_users_2023_06023/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_06023/edit/:id', 
			name: 'club_users_2023_06023edit', 
			component: () => import('pages/club_users_2023_06023/edit.vue'), 
			props: true 
		},

//club_users_2023_0724 routes
		{
			path: '/club_users_2023_0724/:fieldName?/:fieldValue?',
			name: 'club_users_2023_0724list',
			component: () => import('pages/club_users_2023_0724/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2023_0724/view/:id', 
			name: 'club_users_2023_0724view', 
			component: () => import('pages/club_users_2023_0724/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_0724/add', 
			name: 'club_users_2023_0724add', 
			component: () => import('pages/club_users_2023_0724/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_0724/edit/:id', 
			name: 'club_users_2023_0724edit', 
			component: () => import('pages/club_users_2023_0724/edit.vue'), 
			props: true 
		},

//club_users_2023_0724 routes
		{
			path: '/club_users_2023_0724/:fieldName?/:fieldValue?',
			name: 'club_users_2023_0724list',
			component: () => import('pages/club_users_2023_0724/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2023_0724/view/:id', 
			name: 'club_users_2023_0724view', 
			component: () => import('pages/club_users_2023_0724/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_0724/add', 
			name: 'club_users_2023_0724add', 
			component: () => import('pages/club_users_2023_0724/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_0724/edit/:id', 
			name: 'club_users_2023_0724edit', 
			component: () => import('pages/club_users_2023_0724/edit.vue'), 
			props: true 
		},

//club_users_2023_11_24 routes
		{
			path: '/club_users_2023_11_24/:fieldName?/:fieldValue?',
			name: 'club_users_2023_11_24list',
			component: () => import('pages/club_users_2023_11_24/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2023_11_24/view/:id', 
			name: 'club_users_2023_11_24view', 
			component: () => import('pages/club_users_2023_11_24/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_11_24/add', 
			name: 'club_users_2023_11_24add', 
			component: () => import('pages/club_users_2023_11_24/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_11_24/edit/:id', 
			name: 'club_users_2023_11_24edit', 
			component: () => import('pages/club_users_2023_11_24/edit.vue'), 
			props: true 
		},

//club_users_2023_11_24 routes
		{
			path: '/club_users_2023_11_24/:fieldName?/:fieldValue?',
			name: 'club_users_2023_11_24list',
			component: () => import('pages/club_users_2023_11_24/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2023_11_24/view/:id', 
			name: 'club_users_2023_11_24view', 
			component: () => import('pages/club_users_2023_11_24/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_11_24/add', 
			name: 'club_users_2023_11_24add', 
			component: () => import('pages/club_users_2023_11_24/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2023_11_24/edit/:id', 
			name: 'club_users_2023_11_24edit', 
			component: () => import('pages/club_users_2023_11_24/edit.vue'), 
			props: true 
		},

//club_users_2024_03_15 routes
		{
			path: '/club_users_2024_03_15/:fieldName?/:fieldValue?',
			name: 'club_users_2024_03_15list',
			component: () => import('pages/club_users_2024_03_15/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2024_03_15/view/:id', 
			name: 'club_users_2024_03_15view', 
			component: () => import('pages/club_users_2024_03_15/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2024_03_15/add', 
			name: 'club_users_2024_03_15add', 
			component: () => import('pages/club_users_2024_03_15/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2024_03_15/edit/:id', 
			name: 'club_users_2024_03_15edit', 
			component: () => import('pages/club_users_2024_03_15/edit.vue'), 
			props: true 
		},

//club_users_2024_03_15 routes
		{
			path: '/club_users_2024_03_15/:fieldName?/:fieldValue?',
			name: 'club_users_2024_03_15list',
			component: () => import('pages/club_users_2024_03_15/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/club_users_2024_03_15/view/:id', 
			name: 'club_users_2024_03_15view', 
			component: () => import('pages/club_users_2024_03_15/view.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2024_03_15/add', 
			name: 'club_users_2024_03_15add', 
			component: () => import('pages/club_users_2024_03_15/add.vue'), 
			props: true 
		},
		{ 
			path: '/club_users_2024_03_15/edit/:id', 
			name: 'club_users_2024_03_15edit', 
			component: () => import('pages/club_users_2024_03_15/edit.vue'), 
			props: true 
		},

//clubclaim routes

//clubdetails routes

//clubmembers routes

//clubmembership routes
		{
			path: '/clubmembership/:fieldName?/:fieldValue?',
			name: 'clubmembershiplist',
			component: () => import('pages/clubmembership/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubmembership/view/:id', 
			name: 'clubmembershipview', 
			component: () => import('pages/clubmembership/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubmembership/add', 
			name: 'clubmembershipadd', 
			component: () => import('pages/clubmembership/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubmembership/edit/:id', 
			name: 'clubmembershipedit', 
			component: () => import('pages/clubmembership/edit.vue'), 
			props: true 
		},

//clubmembershippackages routes
		{
			path: '/clubmembershippackages/:fieldName?/:fieldValue?',
			name: 'clubmembershippackageslist',
			component: () => import('pages/clubmembershippackages/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubmembershippackages/view/:id', 
			name: 'clubmembershippackagesview', 
			component: () => import('pages/clubmembershippackages/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubmembershippackages/add', 
			name: 'clubmembershippackagesadd', 
			component: () => import('pages/clubmembershippackages/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubmembershippackages/edit/:id', 
			name: 'clubmembershippackagesedit', 
			component: () => import('pages/clubmembershippackages/edit.vue'), 
			props: true 
		},

//clubnya routes

//clubroles routes

//clubroles20230913 routes
		{
			path: '/clubroles20230913/:fieldName?/:fieldValue?',
			name: 'clubroles20230913list',
			component: () => import('pages/clubroles20230913/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubroles20230913/view/:id', 
			name: 'clubroles20230913view', 
			component: () => import('pages/clubroles20230913/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubroles20230913/add', 
			name: 'clubroles20230913add', 
			component: () => import('pages/clubroles20230913/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubroles20230913/edit/:id', 
			name: 'clubroles20230913edit', 
			component: () => import('pages/clubroles20230913/edit.vue'), 
			props: true 
		},

//clubs routes
		{
			path: '/clubs/:fieldName?/:fieldValue?',
			name: 'clubslist',
			component: () => import('pages/clubs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubs/view/:id', 
			name: 'clubsview', 
			component: () => import('pages/clubs/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubs/add', 
			name: 'clubsadd', 
			component: () => import('pages/clubs/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubs/edit/:id', 
			name: 'clubsedit', 
			component: () => import('pages/clubs/edit.vue'), 
			props: true 
		},

//clubs_copy_2023_09_13 routes
		{
			path: '/clubs_copy_2023_09_13/:fieldName?/:fieldValue?',
			name: 'clubs_copy_2023_09_13list',
			component: () => import('pages/clubs_copy_2023_09_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubs_copy_2023_09_13/view/:id', 
			name: 'clubs_copy_2023_09_13view', 
			component: () => import('pages/clubs_copy_2023_09_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2023_09_13/add', 
			name: 'clubs_copy_2023_09_13add', 
			component: () => import('pages/clubs_copy_2023_09_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2023_09_13/edit/:id', 
			name: 'clubs_copy_2023_09_13edit', 
			component: () => import('pages/clubs_copy_2023_09_13/edit.vue'), 
			props: true 
		},

//clubs_copy_2023_09_13 routes
		{
			path: '/clubs_copy_2023_09_13/:fieldName?/:fieldValue?',
			name: 'clubs_copy_2023_09_13list',
			component: () => import('pages/clubs_copy_2023_09_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubs_copy_2023_09_13/view/:id', 
			name: 'clubs_copy_2023_09_13view', 
			component: () => import('pages/clubs_copy_2023_09_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2023_09_13/add', 
			name: 'clubs_copy_2023_09_13add', 
			component: () => import('pages/clubs_copy_2023_09_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2023_09_13/edit/:id', 
			name: 'clubs_copy_2023_09_13edit', 
			component: () => import('pages/clubs_copy_2023_09_13/edit.vue'), 
			props: true 
		},

//clubs_copy_2024_03_15 routes
		{
			path: '/clubs_copy_2024_03_15/:fieldName?/:fieldValue?',
			name: 'clubs_copy_2024_03_15list',
			component: () => import('pages/clubs_copy_2024_03_15/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubs_copy_2024_03_15/view/:id', 
			name: 'clubs_copy_2024_03_15view', 
			component: () => import('pages/clubs_copy_2024_03_15/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2024_03_15/add', 
			name: 'clubs_copy_2024_03_15add', 
			component: () => import('pages/clubs_copy_2024_03_15/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2024_03_15/edit/:id', 
			name: 'clubs_copy_2024_03_15edit', 
			component: () => import('pages/clubs_copy_2024_03_15/edit.vue'), 
			props: true 
		},

//clubs_copy_2024_03_15 routes
		{
			path: '/clubs_copy_2024_03_15/:fieldName?/:fieldValue?',
			name: 'clubs_copy_2024_03_15list',
			component: () => import('pages/clubs_copy_2024_03_15/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubs_copy_2024_03_15/view/:id', 
			name: 'clubs_copy_2024_03_15view', 
			component: () => import('pages/clubs_copy_2024_03_15/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2024_03_15/add', 
			name: 'clubs_copy_2024_03_15add', 
			component: () => import('pages/clubs_copy_2024_03_15/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubs_copy_2024_03_15/edit/:id', 
			name: 'clubs_copy_2024_03_15edit', 
			component: () => import('pages/clubs_copy_2024_03_15/edit.vue'), 
			props: true 
		},

//clubuserrequests routes

//clubusers routes
		{
			path: '/clubusers/:fieldName?/:fieldValue?',
			name: 'clubuserslist',
			component: () => import('pages/clubusers/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/clubusers/view/:id', 
			name: 'clubusersview', 
			component: () => import('pages/clubusers/view.vue'), 
			props: true 
		},
		{ 
			path: '/clubusers/add', 
			name: 'clubusersadd', 
			component: () => import('pages/clubusers/add.vue'), 
			props: true 
		},
		{ 
			path: '/clubusers/edit/:id', 
			name: 'clubusersedit', 
			component: () => import('pages/clubusers/edit.vue'), 
			props: true 
		},

//clubusers202306023 routes

//clubusers20230724 routes

//contents routes

//crm_menu_permissions routes
		{
			path: '/crm_menu_permissions/:fieldName?/:fieldValue?',
			name: 'crm_menu_permissionslist',
			component: () => import('pages/crm_menu_permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crm_menu_permissions/view/:id', 
			name: 'crm_menu_permissionsview', 
			component: () => import('pages/crm_menu_permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/crm_menu_permissions/add', 
			name: 'crm_menu_permissionsadd', 
			component: () => import('pages/crm_menu_permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/crm_menu_permissions/edit/:id', 
			name: 'crm_menu_permissionsedit', 
			component: () => import('pages/crm_menu_permissions/edit.vue'), 
			props: true 
		},

//crm_menus routes
		{
			path: '/crm_menus/:fieldName?/:fieldValue?',
			name: 'crm_menuslist',
			component: () => import('pages/crm_menus/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crm_menus/view/:id', 
			name: 'crm_menusview', 
			component: () => import('pages/crm_menus/view.vue'), 
			props: true 
		},
		{ 
			path: '/crm_menus/add', 
			name: 'crm_menusadd', 
			component: () => import('pages/crm_menus/add.vue'), 
			props: true 
		},
		{ 
			path: '/crm_menus/edit/:id', 
			name: 'crm_menusedit', 
			component: () => import('pages/crm_menus/edit.vue'), 
			props: true 
		},

//crm_permissions routes
		{
			path: '/crm_permissions/:fieldName?/:fieldValue?',
			name: 'crm_permissionslist',
			component: () => import('pages/crm_permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crm_permissions/view/:id', 
			name: 'crm_permissionsview', 
			component: () => import('pages/crm_permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/crm_permissions/add', 
			name: 'crm_permissionsadd', 
			component: () => import('pages/crm_permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/crm_permissions/edit/:id', 
			name: 'crm_permissionsedit', 
			component: () => import('pages/crm_permissions/edit.vue'), 
			props: true 
		},

//crm_role_menus routes
		{
			path: '/crm_role_menus/:fieldName?/:fieldValue?',
			name: 'crm_role_menuslist',
			component: () => import('pages/crm_role_menus/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crm_role_menus/view/:id', 
			name: 'crm_role_menusview', 
			component: () => import('pages/crm_role_menus/view.vue'), 
			props: true 
		},
		{ 
			path: '/crm_role_menus/add', 
			name: 'crm_role_menusadd', 
			component: () => import('pages/crm_role_menus/add.vue'), 
			props: true 
		},
		{ 
			path: '/crm_role_menus/edit/:id', 
			name: 'crm_role_menusedit', 
			component: () => import('pages/crm_role_menus/edit.vue'), 
			props: true 
		},

//crm_role_permissions routes
		{
			path: '/crm_role_permissions/:fieldName?/:fieldValue?',
			name: 'crm_role_permissionslist',
			component: () => import('pages/crm_role_permissions/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crm_role_permissions/view/:id', 
			name: 'crm_role_permissionsview', 
			component: () => import('pages/crm_role_permissions/view.vue'), 
			props: true 
		},
		{ 
			path: '/crm_role_permissions/add', 
			name: 'crm_role_permissionsadd', 
			component: () => import('pages/crm_role_permissions/add.vue'), 
			props: true 
		},
		{ 
			path: '/crm_role_permissions/edit/:id', 
			name: 'crm_role_permissionsedit', 
			component: () => import('pages/crm_role_permissions/edit.vue'), 
			props: true 
		},

//crm_role_provinces routes
		{
			path: '/crm_role_provinces/:fieldName?/:fieldValue?',
			name: 'crm_role_provinceslist',
			component: () => import('pages/crm_role_provinces/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crm_role_provinces/view/:id', 
			name: 'crm_role_provincesview', 
			component: () => import('pages/crm_role_provinces/view.vue'), 
			props: true 
		},
		{ 
			path: '/crm_role_provinces/add', 
			name: 'crm_role_provincesadd', 
			component: () => import('pages/crm_role_provinces/add.vue'), 
			props: true 
		},
		{ 
			path: '/crm_role_provinces/edit/:id', 
			name: 'crm_role_provincesedit', 
			component: () => import('pages/crm_role_provinces/edit.vue'), 
			props: true 
		},

//crmmenupermissions routes

//crmmenus routes

//crmpermissions routes

//crmrolemenus routes

//crmrolepermissions routes

//crmroleprovinces routes

//crmusr routes
		{
			path: '/crmusr/:fieldName?/:fieldValue?',
			name: 'crmusrlist',
			component: () => import('pages/crmusr/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/crmusr/view/:id', 
			name: 'crmusrview', 
			component: () => import('pages/crmusr/view.vue'), 
			props: true 
		},
		{ 
			path: '/crmusr/add', 
			name: 'crmusradd', 
			component: () => import('pages/crmusr/add.vue'), 
			props: true 
		},
		{ 
			path: '/crmusr/edit/:id', 
			name: 'crmusredit', 
			component: () => import('pages/crmusr/edit.vue'), 
			props: true 
		},

//ct_comment routes
		{
			path: '/ct_comment/:fieldName?/:fieldValue?',
			name: 'ct_commentlist',
			component: () => import('pages/ct_comment/list.vue'),
			props: route => passRouteToProps(route)
		},

//ct_community routes
		{
			path: '/ct_community/:fieldName?/:fieldValue?',
			name: 'ct_communitylist',
			component: () => import('pages/ct_community/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/ct_community/view/:id', 
			name: 'ct_communityview', 
			component: () => import('pages/ct_community/view.vue'), 
			props: true 
		},
		{ 
			path: '/ct_community/add', 
			name: 'ct_communityadd', 
			component: () => import('pages/ct_community/add.vue'), 
			props: true 
		},
		{ 
			path: '/ct_community/edit/:id', 
			name: 'ct_communityedit', 
			component: () => import('pages/ct_community/edit.vue'), 
			props: true 
		},

//ct_post routes
		{
			path: '/ct_post/:fieldName?/:fieldValue?',
			name: 'ct_postlist',
			component: () => import('pages/ct_post/list.vue'),
			props: route => passRouteToProps(route)
		},

//ct_reaction routes
		{
			path: '/ct_reaction/:fieldName?/:fieldValue?',
			name: 'ct_reactionlist',
			component: () => import('pages/ct_reaction/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/ct_reaction/view/:id', 
			name: 'ct_reactionview', 
			component: () => import('pages/ct_reaction/view.vue'), 
			props: true 
		},
		{ 
			path: '/ct_reaction/add', 
			name: 'ct_reactionadd', 
			component: () => import('pages/ct_reaction/add.vue'), 
			props: true 
		},
		{ 
			path: '/ct_reaction/edit/:id', 
			name: 'ct_reactionedit', 
			component: () => import('pages/ct_reaction/edit.vue'), 
			props: true 
		},

//ctcomment routes

//ctcommunity routes
		{
			path: '/ctcommunity/:fieldName?/:fieldValue?',
			name: 'ctcommunitylist',
			component: () => import('pages/ctcommunity/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/ctcommunity/view/:id', 
			name: 'ctcommunityview', 
			component: () => import('pages/ctcommunity/view.vue'), 
			props: true 
		},
		{ 
			path: '/ctcommunity/add', 
			name: 'ctcommunityadd', 
			component: () => import('pages/ctcommunity/add.vue'), 
			props: true 
		},
		{ 
			path: '/ctcommunity/edit/:id', 
			name: 'ctcommunityedit', 
			component: () => import('pages/ctcommunity/edit.vue'), 
			props: true 
		},

//ctpost routes

//ctreaction routes

//data_kta routes
		{
			path: '/data_kta/:fieldName?/:fieldValue?',
			name: 'data_ktalist',
			component: () => import('pages/data_kta/list.vue'),
			props: route => passRouteToProps(route)
		},

//datakta routes

//districts routes
		{
			path: '/districts/:fieldName?/:fieldValue?',
			name: 'districtslist',
			component: () => import('pages/districts/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/districts/view/:id', 
			name: 'districtsview', 
			component: () => import('pages/districts/view.vue'), 
			props: true 
		},
		{ 
			path: '/districts/add', 
			name: 'districtsadd', 
			component: () => import('pages/districts/add.vue'), 
			props: true 
		},
		{ 
			path: '/districts/edit/:id', 
			name: 'districtsedit', 
			component: () => import('pages/districts/edit.vue'), 
			props: true 
		},

//domisili routes

//events routes

//fiturs routes
		{
			path: '/fiturs/:fieldName?/:fieldValue?',
			name: 'fiturslist',
			component: () => import('pages/fiturs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/fiturs/view/:id', 
			name: 'fitursview', 
			component: () => import('pages/fiturs/view.vue'), 
			props: true 
		},
		{ 
			path: '/fiturs/add', 
			name: 'fitursadd', 
			component: () => import('pages/fiturs/add.vue'), 
			props: true 
		},
		{ 
			path: '/fiturs/edit/:id', 
			name: 'fitursedit', 
			component: () => import('pages/fiturs/edit.vue'), 
			props: true 
		},

//islands routes

//kendaraan_products routes
		{
			path: '/kendaraan_products/:fieldName?/:fieldValue?',
			name: 'kendaraan_productslist',
			component: () => import('pages/kendaraan_products/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/kendaraan_products/view/:id', 
			name: 'kendaraan_productsview', 
			component: () => import('pages/kendaraan_products/view.vue'), 
			props: true 
		},
		{ 
			path: '/kendaraan_products/add', 
			name: 'kendaraan_productsadd', 
			component: () => import('pages/kendaraan_products/add.vue'), 
			props: true 
		},
		{ 
			path: '/kendaraan_products/edit/:id', 
			name: 'kendaraan_productsedit', 
			component: () => import('pages/kendaraan_products/edit.vue'), 
			props: true 
		},

//kis_category routes
		{
			path: '/kis_category/:fieldName?/:fieldValue?',
			name: 'kis_categorylist',
			component: () => import('pages/kis_category/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/kis_category/view/:id', 
			name: 'kis_categoryview', 
			component: () => import('pages/kis_category/view.vue'), 
			props: true 
		},
		{ 
			path: '/kis_category/add', 
			name: 'kis_categoryadd', 
			component: () => import('pages/kis_category/add.vue'), 
			props: true 
		},
		{ 
			path: '/kis_category/edit/:id', 
			name: 'kis_categoryedit', 
			component: () => import('pages/kis_category/edit.vue'), 
			props: true 
		},

//kis_province routes
		{
			path: '/kis_province/:fieldName?/:fieldValue?',
			name: 'kis_provincelist',
			component: () => import('pages/kis_province/list.vue'),
			props: route => passRouteToProps(route)
		},

//kis_users routes
		{
			path: '/kis_users/:fieldName?/:fieldValue?',
			name: 'kis_userslist',
			component: () => import('pages/kis_users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/kis_users/view/:id', 
			name: 'kis_usersview', 
			component: () => import('pages/kis_users/view.vue'), 
			props: true 
		},
		{ 
			path: '/kis_users/add', 
			name: 'kis_usersadd', 
			component: () => import('pages/kis_users/add.vue'), 
			props: true 
		},
		{ 
			path: '/kis_users/edit/:id', 
			name: 'kis_usersedit', 
			component: () => import('pages/kis_users/edit.vue'), 
			props: true 
		},

//kiscategory routes

//kisnya routes
		{
			path: '/kisnya/:fieldName?/:fieldValue?',
			name: 'kisnyalist',
			component: () => import('pages/kisnya/list.vue'),
			props: route => passRouteToProps(route)
		},

//kisprovince routes

//kisusers routes

//kta routes
		{
			path: '/kta/:fieldName?/:fieldValue?',
			name: 'ktalist',
			component: () => import('pages/kta/list.vue'),
			props: route => passRouteToProps(route)
		},

//kta_claims routes
		{
			path: '/kta_claims/:fieldName?/:fieldValue?',
			name: 'kta_claimslist',
			component: () => import('pages/kta_claims/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/kta_claims/view/:id', 
			name: 'kta_claimsview', 
			component: () => import('pages/kta_claims/view.vue'), 
			props: true 
		},
		{ 
			path: '/kta_claims/add', 
			name: 'kta_claimsadd', 
			component: () => import('pages/kta_claims/add.vue'), 
			props: true 
		},
		{ 
			path: '/kta_claims/edit/:id', 
			name: 'kta_claimsedit', 
			component: () => import('pages/kta_claims/edit.vue'), 
			props: true 
		},

//kta_crm routes
		{
			path: '/kta_crm/:fieldName?/:fieldValue?',
			name: 'kta_crmlist',
			component: () => import('pages/kta_crm/list.vue'),
			props: route => passRouteToProps(route)
		},

//ktaclaims routes

//ktacrm routes
		{
			path: '/ktacrm/:fieldName?/:fieldValue?',
			name: 'ktacrmlist',
			component: () => import('pages/ktacrm/list.vue'),
			props: route => passRouteToProps(route)
		},

//lapmember routes
		{
			path: '/lapmember/:fieldName?/:fieldValue?',
			name: 'lapmemberlist',
			component: () => import('pages/lapmember/list.vue'),
			props: route => passRouteToProps(route)
		},

//listaccs routes
		{
			path: '/listaccs/:fieldName?/:fieldValue?',
			name: 'listaccslist',
			component: () => import('pages/listaccs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/listaccs/view/:id', 
			name: 'listaccsview', 
			component: () => import('pages/listaccs/view.vue'), 
			props: true 
		},
		{ 
			path: '/listaccs/add', 
			name: 'listaccsadd', 
			component: () => import('pages/listaccs/add.vue'), 
			props: true 
		},
		{ 
			path: '/listaccs/edit/:id', 
			name: 'listaccsedit', 
			component: () => import('pages/listaccs/edit.vue'), 
			props: true 
		},

//log_activities routes
		{
			path: '/log_activities/:fieldName?/:fieldValue?',
			name: 'log_activitieslist',
			component: () => import('pages/log_activities/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/log_activities/view/:id', 
			name: 'log_activitiesview', 
			component: () => import('pages/log_activities/view.vue'), 
			props: true 
		},
		{ 
			path: '/log_activities/add', 
			name: 'log_activitiesadd', 
			component: () => import('pages/log_activities/add.vue'), 
			props: true 
		},
		{ 
			path: '/log_activities/edit/:id', 
			name: 'log_activitiesedit', 
			component: () => import('pages/log_activities/edit.vue'), 
			props: true 
		},

//logactivities routes

//member routes

//membership routes

//membership_orders routes
		{
			path: '/membership_orders/:fieldName?/:fieldValue?',
			name: 'membership_orderslist',
			component: () => import('pages/membership_orders/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_orders/view/:id', 
			name: 'membership_ordersview', 
			component: () => import('pages/membership_orders/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_orders/add', 
			name: 'membership_ordersadd', 
			component: () => import('pages/membership_orders/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_orders/edit/:id', 
			name: 'membership_ordersedit', 
			component: () => import('pages/membership_orders/edit.vue'), 
			props: true 
		},

//membership_orders_copy1 routes
		{
			path: '/membership_orders_copy1/:fieldName?/:fieldValue?',
			name: 'membership_orders_copy1list',
			component: () => import('pages/membership_orders_copy1/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_orders_copy1/view/:id', 
			name: 'membership_orders_copy1view', 
			component: () => import('pages/membership_orders_copy1/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_orders_copy1/add', 
			name: 'membership_orders_copy1add', 
			component: () => import('pages/membership_orders_copy1/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_orders_copy1/edit/:id', 
			name: 'membership_orders_copy1edit', 
			component: () => import('pages/membership_orders_copy1/edit.vue'), 
			props: true 
		},

//membership_user_update routes
		{
			path: '/membership_user_update/:fieldName?/:fieldValue?',
			name: 'membership_user_updatelist',
			component: () => import('pages/membership_user_update/list.vue'),
			props: route => passRouteToProps(route)
		},

//membership_users routes
		{
			path: '/membership_users/:fieldName?/:fieldValue?',
			name: 'membership_userslist',
			component: () => import('pages/membership_users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users/view/:id', 
			name: 'membership_usersview', 
			component: () => import('pages/membership_users/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users/add', 
			name: 'membership_usersadd', 
			component: () => import('pages/membership_users/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users/edit/:id', 
			name: 'membership_usersedit', 
			component: () => import('pages/membership_users/edit.vue'), 
			props: true 
		},

//membership_users_2023_11_13 routes
		{
			path: '/membership_users_2023_11_13/:fieldName?/:fieldValue?',
			name: 'membership_users_2023_11_13list',
			component: () => import('pages/membership_users_2023_11_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users_2023_11_13/view/:id', 
			name: 'membership_users_2023_11_13view', 
			component: () => import('pages/membership_users_2023_11_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2023_11_13/add', 
			name: 'membership_users_2023_11_13add', 
			component: () => import('pages/membership_users_2023_11_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2023_11_13/edit/:id', 
			name: 'membership_users_2023_11_13edit', 
			component: () => import('pages/membership_users_2023_11_13/edit.vue'), 
			props: true 
		},

//membership_users_2023_11_13 routes
		{
			path: '/membership_users_2023_11_13/:fieldName?/:fieldValue?',
			name: 'membership_users_2023_11_13list',
			component: () => import('pages/membership_users_2023_11_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users_2023_11_13/view/:id', 
			name: 'membership_users_2023_11_13view', 
			component: () => import('pages/membership_users_2023_11_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2023_11_13/add', 
			name: 'membership_users_2023_11_13add', 
			component: () => import('pages/membership_users_2023_11_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2023_11_13/edit/:id', 
			name: 'membership_users_2023_11_13edit', 
			component: () => import('pages/membership_users_2023_11_13/edit.vue'), 
			props: true 
		},

//membership_users_20230911 routes
		{
			path: '/membership_users_20230911/:fieldName?/:fieldValue?',
			name: 'membership_users_20230911list',
			component: () => import('pages/membership_users_20230911/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users_20230911/view/:id', 
			name: 'membership_users_20230911view', 
			component: () => import('pages/membership_users_20230911/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_20230911/add', 
			name: 'membership_users_20230911add', 
			component: () => import('pages/membership_users_20230911/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_20230911/edit/:id', 
			name: 'membership_users_20230911edit', 
			component: () => import('pages/membership_users_20230911/edit.vue'), 
			props: true 
		},

//membership_users_2024_04_16 routes
		{
			path: '/membership_users_2024_04_16/:fieldName?/:fieldValue?',
			name: 'membership_users_2024_04_16list',
			component: () => import('pages/membership_users_2024_04_16/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users_2024_04_16/view/:id', 
			name: 'membership_users_2024_04_16view', 
			component: () => import('pages/membership_users_2024_04_16/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2024_04_16/add', 
			name: 'membership_users_2024_04_16add', 
			component: () => import('pages/membership_users_2024_04_16/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2024_04_16/edit/:id', 
			name: 'membership_users_2024_04_16edit', 
			component: () => import('pages/membership_users_2024_04_16/edit.vue'), 
			props: true 
		},

//membership_users_2024_04_16 routes
		{
			path: '/membership_users_2024_04_16/:fieldName?/:fieldValue?',
			name: 'membership_users_2024_04_16list',
			component: () => import('pages/membership_users_2024_04_16/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users_2024_04_16/view/:id', 
			name: 'membership_users_2024_04_16view', 
			component: () => import('pages/membership_users_2024_04_16/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2024_04_16/add', 
			name: 'membership_users_2024_04_16add', 
			component: () => import('pages/membership_users_2024_04_16/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_2024_04_16/edit/:id', 
			name: 'membership_users_2024_04_16edit', 
			component: () => import('pages/membership_users_2024_04_16/edit.vue'), 
			props: true 
		},

//membership_users_tampung_dbl routes
		{
			path: '/membership_users_tampung_dbl/:fieldName?/:fieldValue?',
			name: 'membership_users_tampung_dbllist',
			component: () => import('pages/membership_users_tampung_dbl/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membership_users_tampung_dbl/view/:id', 
			name: 'membership_users_tampung_dblview', 
			component: () => import('pages/membership_users_tampung_dbl/view.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_tampung_dbl/add', 
			name: 'membership_users_tampung_dbladd', 
			component: () => import('pages/membership_users_tampung_dbl/add.vue'), 
			props: true 
		},
		{ 
			path: '/membership_users_tampung_dbl/edit/:id', 
			name: 'membership_users_tampung_dbledit', 
			component: () => import('pages/membership_users_tampung_dbl/edit.vue'), 
			props: true 
		},

//membershiporders routes
		{
			path: '/membershiporders/:fieldName?/:fieldValue?',
			name: 'membershiporderslist',
			component: () => import('pages/membershiporders/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membershiporders/view/:id', 
			name: 'membershipordersview', 
			component: () => import('pages/membershiporders/view.vue'), 
			props: true 
		},
		{ 
			path: '/membershiporders/add', 
			name: 'membershipordersadd', 
			component: () => import('pages/membershiporders/add.vue'), 
			props: true 
		},
		{ 
			path: '/membershiporders/edit/:id', 
			name: 'membershipordersedit', 
			component: () => import('pages/membershiporders/edit.vue'), 
			props: true 
		},

//membershiporderscopy1 routes

//memberships routes

//membershipusers routes
		{
			path: '/membershipusers/:fieldName?/:fieldValue?',
			name: 'membershipuserslist',
			component: () => import('pages/membershipusers/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membershipusers/view/:id', 
			name: 'membershipusersview', 
			component: () => import('pages/membershipusers/view.vue'), 
			props: true 
		},
		{ 
			path: '/membershipusers/add', 
			name: 'membershipusersadd', 
			component: () => import('pages/membershipusers/add.vue'), 
			props: true 
		},
		{ 
			path: '/membershipusers/edit/:id', 
			name: 'membershipusersedit', 
			component: () => import('pages/membershipusers/edit.vue'), 
			props: true 
		},

//membershipusers20230911 routes
		{
			path: '/membershipusers20230911/:fieldName?/:fieldValue?',
			name: 'membershipusers20230911list',
			component: () => import('pages/membershipusers20230911/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membershipusers20230911/view/:id', 
			name: 'membershipusers20230911view', 
			component: () => import('pages/membershipusers20230911/view.vue'), 
			props: true 
		},
		{ 
			path: '/membershipusers20230911/add', 
			name: 'membershipusers20230911add', 
			component: () => import('pages/membershipusers20230911/add.vue'), 
			props: true 
		},
		{ 
			path: '/membershipusers20230911/edit/:id', 
			name: 'membershipusers20230911edit', 
			component: () => import('pages/membershipusers20230911/edit.vue'), 
			props: true 
		},

//membershipuserstampungdbl routes
		{
			path: '/membershipuserstampungdbl/:fieldName?/:fieldValue?',
			name: 'membershipuserstampungdbllist',
			component: () => import('pages/membershipuserstampungdbl/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/membershipuserstampungdbl/view/:id', 
			name: 'membershipuserstampungdblview', 
			component: () => import('pages/membershipuserstampungdbl/view.vue'), 
			props: true 
		},
		{ 
			path: '/membershipuserstampungdbl/add', 
			name: 'membershipuserstampungdbladd', 
			component: () => import('pages/membershipuserstampungdbl/add.vue'), 
			props: true 
		},
		{ 
			path: '/membershipuserstampungdbl/edit/:id', 
			name: 'membershipuserstampungdbledit', 
			component: () => import('pages/membershipuserstampungdbl/edit.vue'), 
			props: true 
		},

//membershipuserupdate routes

//mitras routes

//notifs routes
		{
			path: '/notifs/:fieldName?/:fieldValue?',
			name: 'notifslist',
			component: () => import('pages/notifs/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/notifs/view/:id', 
			name: 'notifsview', 
			component: () => import('pages/notifs/view.vue'), 
			props: true 
		},
		{ 
			path: '/notifs/add', 
			name: 'notifsadd', 
			component: () => import('pages/notifs/add.vue'), 
			props: true 
		},
		{ 
			path: '/notifs/edit/:id', 
			name: 'notifsedit', 
			component: () => import('pages/notifs/edit.vue'), 
			props: true 
		},

//orderan routes

//p_kategori_kendaraan routes
		{
			path: '/p_kategori_kendaraan/:fieldName?/:fieldValue?',
			name: 'p_kategori_kendaraanlist',
			component: () => import('pages/p_kategori_kendaraan/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/p_kategori_kendaraan/view/:id', 
			name: 'p_kategori_kendaraanview', 
			component: () => import('pages/p_kategori_kendaraan/view.vue'), 
			props: true 
		},
		{ 
			path: '/p_kategori_kendaraan/add', 
			name: 'p_kategori_kendaraanadd', 
			component: () => import('pages/p_kategori_kendaraan/add.vue'), 
			props: true 
		},
		{ 
			path: '/p_kategori_kendaraan/edit/:id', 
			name: 'p_kategori_kendaraanedit', 
			component: () => import('pages/p_kategori_kendaraan/edit.vue'), 
			props: true 
		},

//p_merk_kendaraan routes
		{
			path: '/p_merk_kendaraan/:fieldName?/:fieldValue?',
			name: 'p_merk_kendaraanlist',
			component: () => import('pages/p_merk_kendaraan/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/p_merk_kendaraan/view/:id', 
			name: 'p_merk_kendaraanview', 
			component: () => import('pages/p_merk_kendaraan/view.vue'), 
			props: true 
		},
		{ 
			path: '/p_merk_kendaraan/add', 
			name: 'p_merk_kendaraanadd', 
			component: () => import('pages/p_merk_kendaraan/add.vue'), 
			props: true 
		},
		{ 
			path: '/p_merk_kendaraan/edit/:id', 
			name: 'p_merk_kendaraanedit', 
			component: () => import('pages/p_merk_kendaraan/edit.vue'), 
			props: true 
		},

//p_plat_nomor routes
		{
			path: '/p_plat_nomor/:fieldName?/:fieldValue?',
			name: 'p_plat_nomorlist',
			component: () => import('pages/p_plat_nomor/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/p_plat_nomor/view/:id', 
			name: 'p_plat_nomorview', 
			component: () => import('pages/p_plat_nomor/view.vue'), 
			props: true 
		},
		{ 
			path: '/p_plat_nomor/add', 
			name: 'p_plat_nomoradd', 
			component: () => import('pages/p_plat_nomor/add.vue'), 
			props: true 
		},
		{ 
			path: '/p_plat_nomor/edit/:id', 
			name: 'p_plat_nomoredit', 
			component: () => import('pages/p_plat_nomor/edit.vue'), 
			props: true 
		},

//p_tipe_kendaraan routes
		{
			path: '/p_tipe_kendaraan/:fieldName?/:fieldValue?',
			name: 'p_tipe_kendaraanlist',
			component: () => import('pages/p_tipe_kendaraan/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/p_tipe_kendaraan/view/:id', 
			name: 'p_tipe_kendaraanview', 
			component: () => import('pages/p_tipe_kendaraan/view.vue'), 
			props: true 
		},
		{ 
			path: '/p_tipe_kendaraan/add', 
			name: 'p_tipe_kendaraanadd', 
			component: () => import('pages/p_tipe_kendaraan/add.vue'), 
			props: true 
		},
		{ 
			path: '/p_tipe_kendaraan/edit/:id', 
			name: 'p_tipe_kendaraanedit', 
			component: () => import('pages/p_tipe_kendaraan/edit.vue'), 
			props: true 
		},

//p_user_favor_post routes
		{
			path: '/p_user_favor_post/:fieldName?/:fieldValue?',
			name: 'p_user_favor_postlist',
			component: () => import('pages/p_user_favor_post/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/p_user_favor_post/view/:id', 
			name: 'p_user_favor_postview', 
			component: () => import('pages/p_user_favor_post/view.vue'), 
			props: true 
		},
		{ 
			path: '/p_user_favor_post/add', 
			name: 'p_user_favor_postadd', 
			component: () => import('pages/p_user_favor_post/add.vue'), 
			props: true 
		},
		{ 
			path: '/p_user_favor_post/edit/:id', 
			name: 'p_user_favor_postedit', 
			component: () => import('pages/p_user_favor_post/edit.vue'), 
			props: true 
		},

//partnership_banners routes
		{
			path: '/partnership_banners/:fieldName?/:fieldValue?',
			name: 'partnership_bannerslist',
			component: () => import('pages/partnership_banners/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnership_banners/view/:id', 
			name: 'partnership_bannersview', 
			component: () => import('pages/partnership_banners/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnership_banners/add', 
			name: 'partnership_bannersadd', 
			component: () => import('pages/partnership_banners/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnership_banners/edit/:id', 
			name: 'partnership_bannersedit', 
			component: () => import('pages/partnership_banners/edit.vue'), 
			props: true 
		},

//partnership_categories routes
		{
			path: '/partnership_categories/:fieldName?/:fieldValue?',
			name: 'partnership_categorieslist',
			component: () => import('pages/partnership_categories/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnership_categories/view/:id', 
			name: 'partnership_categoriesview', 
			component: () => import('pages/partnership_categories/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnership_categories/add', 
			name: 'partnership_categoriesadd', 
			component: () => import('pages/partnership_categories/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnership_categories/edit/:id', 
			name: 'partnership_categoriesedit', 
			component: () => import('pages/partnership_categories/edit.vue'), 
			props: true 
		},

//partnership_ctg_banners routes
		{
			path: '/partnership_ctg_banners/:fieldName?/:fieldValue?',
			name: 'partnership_ctg_bannerslist',
			component: () => import('pages/partnership_ctg_banners/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnership_ctg_banners/view/:id', 
			name: 'partnership_ctg_bannersview', 
			component: () => import('pages/partnership_ctg_banners/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnership_ctg_banners/add', 
			name: 'partnership_ctg_bannersadd', 
			component: () => import('pages/partnership_ctg_banners/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnership_ctg_banners/edit/:id', 
			name: 'partnership_ctg_bannersedit', 
			component: () => import('pages/partnership_ctg_banners/edit.vue'), 
			props: true 
		},

//partnershipbanners routes

//partnershipcategories routes

//partnershipctgbanners routes
		{
			path: '/partnershipctgbanners/:fieldName?/:fieldValue?',
			name: 'partnershipctgbannerslist',
			component: () => import('pages/partnershipctgbanners/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnershipctgbanners/view/:id', 
			name: 'partnershipctgbannersview', 
			component: () => import('pages/partnershipctgbanners/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnershipctgbanners/add', 
			name: 'partnershipctgbannersadd', 
			component: () => import('pages/partnershipctgbanners/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnershipctgbanners/edit/:id', 
			name: 'partnershipctgbannersedit', 
			component: () => import('pages/partnershipctgbanners/edit.vue'), 
			props: true 
		},

//partnerships routes

//partnerships_2023_09_13 routes
		{
			path: '/partnerships_2023_09_13/:fieldName?/:fieldValue?',
			name: 'partnerships_2023_09_13list',
			component: () => import('pages/partnerships_2023_09_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnerships_2023_09_13/view/:id', 
			name: 'partnerships_2023_09_13view', 
			component: () => import('pages/partnerships_2023_09_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnerships_2023_09_13/add', 
			name: 'partnerships_2023_09_13add', 
			component: () => import('pages/partnerships_2023_09_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnerships_2023_09_13/edit/:id', 
			name: 'partnerships_2023_09_13edit', 
			component: () => import('pages/partnerships_2023_09_13/edit.vue'), 
			props: true 
		},

//partnerships_2023_09_13 routes
		{
			path: '/partnerships_2023_09_13/:fieldName?/:fieldValue?',
			name: 'partnerships_2023_09_13list',
			component: () => import('pages/partnerships_2023_09_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnerships_2023_09_13/view/:id', 
			name: 'partnerships_2023_09_13view', 
			component: () => import('pages/partnerships_2023_09_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnerships_2023_09_13/add', 
			name: 'partnerships_2023_09_13add', 
			component: () => import('pages/partnerships_2023_09_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnerships_2023_09_13/edit/:id', 
			name: 'partnerships_2023_09_13edit', 
			component: () => import('pages/partnerships_2023_09_13/edit.vue'), 
			props: true 
		},

//partnerships20230913 routes
		{
			path: '/partnerships20230913/:fieldName?/:fieldValue?',
			name: 'partnerships20230913list',
			component: () => import('pages/partnerships20230913/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/partnerships20230913/view/:id', 
			name: 'partnerships20230913view', 
			component: () => import('pages/partnerships20230913/view.vue'), 
			props: true 
		},
		{ 
			path: '/partnerships20230913/add', 
			name: 'partnerships20230913add', 
			component: () => import('pages/partnerships20230913/add.vue'), 
			props: true 
		},
		{ 
			path: '/partnerships20230913/edit/:id', 
			name: 'partnerships20230913edit', 
			component: () => import('pages/partnerships20230913/edit.vue'), 
			props: true 
		},

//pk_kendaraans routes
		{
			path: '/pk_kendaraans/:fieldName?/:fieldValue?',
			name: 'pk_kendaraanslist',
			component: () => import('pages/pk_kendaraans/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pk_kendaraans/view/:id', 
			name: 'pk_kendaraansview', 
			component: () => import('pages/pk_kendaraans/view.vue'), 
			props: true 
		},
		{ 
			path: '/pk_kendaraans/add', 
			name: 'pk_kendaraansadd', 
			component: () => import('pages/pk_kendaraans/add.vue'), 
			props: true 
		},
		{ 
			path: '/pk_kendaraans/edit/:id', 
			name: 'pk_kendaraansedit', 
			component: () => import('pages/pk_kendaraans/edit.vue'), 
			props: true 
		},

//pkategorikendaraan routes

//pkkendaraans routes

//pm_order routes
		{
			path: '/pm_order/:fieldName?/:fieldValue?',
			name: 'pm_orderlist',
			component: () => import('pages/pm_order/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pm_order/view/:id', 
			name: 'pm_orderview', 
			component: () => import('pages/pm_order/view.vue'), 
			props: true 
		},
		{ 
			path: '/pm_order/add', 
			name: 'pm_orderadd', 
			component: () => import('pages/pm_order/add.vue'), 
			props: true 
		},
		{ 
			path: '/pm_order/edit/:id', 
			name: 'pm_orderedit', 
			component: () => import('pages/pm_order/edit.vue'), 
			props: true 
		},

//pm_order_item routes
		{
			path: '/pm_order_item/:fieldName?/:fieldValue?',
			name: 'pm_order_itemlist',
			component: () => import('pages/pm_order_item/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/pm_order_item/view/:id', 
			name: 'pm_order_itemview', 
			component: () => import('pages/pm_order_item/view.vue'), 
			props: true 
		},
		{ 
			path: '/pm_order_item/add', 
			name: 'pm_order_itemadd', 
			component: () => import('pages/pm_order_item/add.vue'), 
			props: true 
		},
		{ 
			path: '/pm_order_item/edit/:id', 
			name: 'pm_order_itemedit', 
			component: () => import('pages/pm_order_item/edit.vue'), 
			props: true 
		},

//pmerkkendaraan routes

//pmorder routes

//pmorderitem routes

//pplatnomor routes

//product_banners routes
		{
			path: '/product_banners/:fieldName?/:fieldValue?',
			name: 'product_bannerslist',
			component: () => import('pages/product_banners/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/product_banners/view/:id', 
			name: 'product_bannersview', 
			component: () => import('pages/product_banners/view.vue'), 
			props: true 
		},
		{ 
			path: '/product_banners/add', 
			name: 'product_bannersadd', 
			component: () => import('pages/product_banners/add.vue'), 
			props: true 
		},
		{ 
			path: '/product_banners/edit/:id', 
			name: 'product_bannersedit', 
			component: () => import('pages/product_banners/edit.vue'), 
			props: true 
		},

//products routes

//provinces routes

//provinces_copy_20230913 routes
		{
			path: '/provinces_copy_20230913/:fieldName?/:fieldValue?',
			name: 'provinces_copy_20230913list',
			component: () => import('pages/provinces_copy_20230913/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/provinces_copy_20230913/view/:id', 
			name: 'provinces_copy_20230913view', 
			component: () => import('pages/provinces_copy_20230913/view.vue'), 
			props: true 
		},
		{ 
			path: '/provinces_copy_20230913/add', 
			name: 'provinces_copy_20230913add', 
			component: () => import('pages/provinces_copy_20230913/add.vue'), 
			props: true 
		},
		{ 
			path: '/provinces_copy_20230913/edit/:id', 
			name: 'provinces_copy_20230913edit', 
			component: () => import('pages/provinces_copy_20230913/edit.vue'), 
			props: true 
		},

//provincescopy20230913 routes

//ptipekendaraan routes

//puserfavorpost routes

//refferal routes
		{
			path: '/refferal/:fieldName?/:fieldValue?',
			name: 'refferallist',
			component: () => import('pages/refferal/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/refferal/view/:id', 
			name: 'refferalview', 
			component: () => import('pages/refferal/view.vue'), 
			props: true 
		},
		{ 
			path: '/refferal/add', 
			name: 'refferaladd', 
			component: () => import('pages/refferal/add.vue'), 
			props: true 
		},
		{ 
			path: '/refferal/edit/:id', 
			name: 'refferaledit', 
			component: () => import('pages/refferal/edit.vue'), 
			props: true 
		},

//report routes
		{
			path: '/report/:fieldName?/:fieldValue?',
			name: 'reportlist',
			component: () => import('pages/report/list.vue'),
			props: route => passRouteToProps(route)
		},

//report_aldy routes
		{
			path: '/report_aldy/:fieldName?/:fieldValue?',
			name: 'report_aldylist',
			component: () => import('pages/report_aldy/list.vue'),
			props: route => passRouteToProps(route)
		},

//report_aldy_copy1 routes
		{
			path: '/report_aldy_copy1/:fieldName?/:fieldValue?',
			name: 'report_aldy_copy1list',
			component: () => import('pages/report_aldy_copy1/list.vue'),
			props: route => passRouteToProps(route)
		},

//reportaldy routes
		{
			path: '/reportaldy/:fieldName?/:fieldValue?',
			name: 'reportaldylist',
			component: () => import('pages/reportaldy/list.vue'),
			props: route => passRouteToProps(route)
		},

//service_orders routes
		{
			path: '/service_orders/:fieldName?/:fieldValue?',
			name: 'service_orderslist',
			component: () => import('pages/service_orders/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/service_orders/view/:id', 
			name: 'service_ordersview', 
			component: () => import('pages/service_orders/view.vue'), 
			props: true 
		},
		{ 
			path: '/service_orders/add', 
			name: 'service_ordersadd', 
			component: () => import('pages/service_orders/add.vue'), 
			props: true 
		},
		{ 
			path: '/service_orders/edit/:id', 
			name: 'service_ordersedit', 
			component: () => import('pages/service_orders/edit.vue'), 
			props: true 
		},

//service_users routes
		{
			path: '/service_users/:fieldName?/:fieldValue?',
			name: 'service_userslist',
			component: () => import('pages/service_users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/service_users/view/:id', 
			name: 'service_usersview', 
			component: () => import('pages/service_users/view.vue'), 
			props: true 
		},
		{ 
			path: '/service_users/add', 
			name: 'service_usersadd', 
			component: () => import('pages/service_users/add.vue'), 
			props: true 
		},
		{ 
			path: '/service_users/edit/:id', 
			name: 'service_usersedit', 
			component: () => import('pages/service_users/edit.vue'), 
			props: true 
		},

//service_vendors routes
		{
			path: '/service_vendors/:fieldName?/:fieldValue?',
			name: 'service_vendorslist',
			component: () => import('pages/service_vendors/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/service_vendors/view/:id', 
			name: 'service_vendorsview', 
			component: () => import('pages/service_vendors/view.vue'), 
			props: true 
		},
		{ 
			path: '/service_vendors/add', 
			name: 'service_vendorsadd', 
			component: () => import('pages/service_vendors/add.vue'), 
			props: true 
		},
		{ 
			path: '/service_vendors/edit/:id', 
			name: 'service_vendorsedit', 
			component: () => import('pages/service_vendors/edit.vue'), 
			props: true 
		},

//serviceorders routes

//services routes

//serviceusers routes

//servicevendors routes

//social_posts routes
		{
			path: '/social_posts/:fieldName?/:fieldValue?',
			name: 'social_postslist',
			component: () => import('pages/social_posts/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/social_posts/view/:id', 
			name: 'social_postsview', 
			component: () => import('pages/social_posts/view.vue'), 
			props: true 
		},
		{ 
			path: '/social_posts/add', 
			name: 'social_postsadd', 
			component: () => import('pages/social_posts/add.vue'), 
			props: true 
		},
		{ 
			path: '/social_posts/edit/:id', 
			name: 'social_postsedit', 
			component: () => import('pages/social_posts/edit.vue'), 
			props: true 
		},

//socialposts routes

//url routes
		{
			path: '/url/:fieldName?/:fieldValue?',
			name: 'urllist',
			component: () => import('pages/url/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/url/view/:id', 
			name: 'urlview', 
			component: () => import('pages/url/view.vue'), 
			props: true 
		},
		{ 
			path: '/url/add', 
			name: 'urladd', 
			component: () => import('pages/url/add.vue'), 
			props: true 
		},
		{ 
			path: '/url/edit/:id', 
			name: 'urledit', 
			component: () => import('pages/url/edit.vue'), 
			props: true 
		},

//url_type routes
		{
			path: '/url_type/:fieldName?/:fieldValue?',
			name: 'url_typelist',
			component: () => import('pages/url_type/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/url_type/view/:id', 
			name: 'url_typeview', 
			component: () => import('pages/url_type/view.vue'), 
			props: true 
		},
		{ 
			path: '/url_type/add', 
			name: 'url_typeadd', 
			component: () => import('pages/url_type/add.vue'), 
			props: true 
		},
		{ 
			path: '/url_type/edit/:id', 
			name: 'url_typeedit', 
			component: () => import('pages/url_type/edit.vue'), 
			props: true 
		},

//user_active routes
		{
			path: '/user_active/:fieldName?/:fieldValue?',
			name: 'user_activelist',
			component: () => import('pages/user_active/list.vue'),
			props: route => passRouteToProps(route)
		},

//user_cards routes
		{
			path: '/user_cards/:fieldName?/:fieldValue?',
			name: 'user_cardslist',
			component: () => import('pages/user_cards/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_cards/view/:id', 
			name: 'user_cardsview', 
			component: () => import('pages/user_cards/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_cards/add', 
			name: 'user_cardsadd', 
			component: () => import('pages/user_cards/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_cards/edit/:id', 
			name: 'user_cardsedit', 
			component: () => import('pages/user_cards/edit.vue'), 
			props: true 
		},

//user_crm_roles routes
		{
			path: '/user_crm_roles/:fieldName?/:fieldValue?',
			name: 'user_crm_roleslist',
			component: () => import('pages/user_crm_roles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_crm_roles/view/:id', 
			name: 'user_crm_rolesview', 
			component: () => import('pages/user_crm_roles/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_crm_roles/add', 
			name: 'user_crm_rolesadd', 
			component: () => import('pages/user_crm_roles/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_crm_roles/edit/:id', 
			name: 'user_crm_rolesedit', 
			component: () => import('pages/user_crm_roles/edit.vue'), 
			props: true 
		},

//user_crms routes
		{
			path: '/user_crms/:fieldName?/:fieldValue?',
			name: 'user_crmslist',
			component: () => import('pages/user_crms/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_crms/view/:id', 
			name: 'user_crmsview', 
			component: () => import('pages/user_crms/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_crms/add', 
			name: 'user_crmsadd', 
			component: () => import('pages/user_crms/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_crms/edit/:id', 
			name: 'user_crmsedit', 
			component: () => import('pages/user_crms/edit.vue'), 
			props: true 
		},

//user_kta_all routes
		{
			path: '/user_kta_all/:fieldName?/:fieldValue?',
			name: 'user_kta_alllist',
			component: () => import('pages/user_kta_all/list.vue'),
			props: route => passRouteToProps(route)
		},

//user_otps routes
		{
			path: '/user_otps/:fieldName?/:fieldValue?',
			name: 'user_otpslist',
			component: () => import('pages/user_otps/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_otps/view/:id', 
			name: 'user_otpsview', 
			component: () => import('pages/user_otps/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_otps/add', 
			name: 'user_otpsadd', 
			component: () => import('pages/user_otps/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_otps/edit/:id', 
			name: 'user_otpsedit', 
			component: () => import('pages/user_otps/edit.vue'), 
			props: true 
		},

//user_profiles routes
		{
			path: '/user_profiles/:fieldName?/:fieldValue?',
			name: 'user_profileslist',
			component: () => import('pages/user_profiles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles/view/:id', 
			name: 'user_profilesview', 
			component: () => import('pages/user_profiles/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles/add', 
			name: 'user_profilesadd', 
			component: () => import('pages/user_profiles/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles/edit/:id', 
			name: 'user_profilesedit', 
			component: () => import('pages/user_profiles/edit.vue'), 
			props: true 
		},

//user_profiles_2023_06_01 routes
		{
			path: '/user_profiles_2023_06_01/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_06_01list',
			component: () => import('pages/user_profiles_2023_06_01/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_06_01/view/:id', 
			name: 'user_profiles_2023_06_01view', 
			component: () => import('pages/user_profiles_2023_06_01/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_01/add', 
			name: 'user_profiles_2023_06_01add', 
			component: () => import('pages/user_profiles_2023_06_01/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_01/edit/:id', 
			name: 'user_profiles_2023_06_01edit', 
			component: () => import('pages/user_profiles_2023_06_01/edit.vue'), 
			props: true 
		},

//user_profiles_2023_06_01 routes
		{
			path: '/user_profiles_2023_06_01/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_06_01list',
			component: () => import('pages/user_profiles_2023_06_01/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_06_01/view/:id', 
			name: 'user_profiles_2023_06_01view', 
			component: () => import('pages/user_profiles_2023_06_01/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_01/add', 
			name: 'user_profiles_2023_06_01add', 
			component: () => import('pages/user_profiles_2023_06_01/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_01/edit/:id', 
			name: 'user_profiles_2023_06_01edit', 
			component: () => import('pages/user_profiles_2023_06_01/edit.vue'), 
			props: true 
		},

//user_profiles_2023_06_19 routes
		{
			path: '/user_profiles_2023_06_19/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_06_19list',
			component: () => import('pages/user_profiles_2023_06_19/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_06_19/view/:id', 
			name: 'user_profiles_2023_06_19view', 
			component: () => import('pages/user_profiles_2023_06_19/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_19/add', 
			name: 'user_profiles_2023_06_19add', 
			component: () => import('pages/user_profiles_2023_06_19/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_19/edit/:id', 
			name: 'user_profiles_2023_06_19edit', 
			component: () => import('pages/user_profiles_2023_06_19/edit.vue'), 
			props: true 
		},

//user_profiles_2023_06_19 routes
		{
			path: '/user_profiles_2023_06_19/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_06_19list',
			component: () => import('pages/user_profiles_2023_06_19/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_06_19/view/:id', 
			name: 'user_profiles_2023_06_19view', 
			component: () => import('pages/user_profiles_2023_06_19/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_19/add', 
			name: 'user_profiles_2023_06_19add', 
			component: () => import('pages/user_profiles_2023_06_19/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_06_19/edit/:id', 
			name: 'user_profiles_2023_06_19edit', 
			component: () => import('pages/user_profiles_2023_06_19/edit.vue'), 
			props: true 
		},

//user_profiles_2023_09_13 routes
		{
			path: '/user_profiles_2023_09_13/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_09_13list',
			component: () => import('pages/user_profiles_2023_09_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_09_13/view/:id', 
			name: 'user_profiles_2023_09_13view', 
			component: () => import('pages/user_profiles_2023_09_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_09_13/add', 
			name: 'user_profiles_2023_09_13add', 
			component: () => import('pages/user_profiles_2023_09_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_09_13/edit/:id', 
			name: 'user_profiles_2023_09_13edit', 
			component: () => import('pages/user_profiles_2023_09_13/edit.vue'), 
			props: true 
		},

//user_profiles_2023_09_13 routes
		{
			path: '/user_profiles_2023_09_13/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_09_13list',
			component: () => import('pages/user_profiles_2023_09_13/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_09_13/view/:id', 
			name: 'user_profiles_2023_09_13view', 
			component: () => import('pages/user_profiles_2023_09_13/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_09_13/add', 
			name: 'user_profiles_2023_09_13add', 
			component: () => import('pages/user_profiles_2023_09_13/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_09_13/edit/:id', 
			name: 'user_profiles_2023_09_13edit', 
			component: () => import('pages/user_profiles_2023_09_13/edit.vue'), 
			props: true 
		},

//user_profiles_2023_11_15 routes
		{
			path: '/user_profiles_2023_11_15/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_11_15list',
			component: () => import('pages/user_profiles_2023_11_15/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_11_15/view/:id', 
			name: 'user_profiles_2023_11_15view', 
			component: () => import('pages/user_profiles_2023_11_15/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_11_15/add', 
			name: 'user_profiles_2023_11_15add', 
			component: () => import('pages/user_profiles_2023_11_15/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_11_15/edit/:id', 
			name: 'user_profiles_2023_11_15edit', 
			component: () => import('pages/user_profiles_2023_11_15/edit.vue'), 
			props: true 
		},

//user_profiles_2023_11_15 routes
		{
			path: '/user_profiles_2023_11_15/:fieldName?/:fieldValue?',
			name: 'user_profiles_2023_11_15list',
			component: () => import('pages/user_profiles_2023_11_15/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_profiles_2023_11_15/view/:id', 
			name: 'user_profiles_2023_11_15view', 
			component: () => import('pages/user_profiles_2023_11_15/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_11_15/add', 
			name: 'user_profiles_2023_11_15add', 
			component: () => import('pages/user_profiles_2023_11_15/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_profiles_2023_11_15/edit/:id', 
			name: 'user_profiles_2023_11_15edit', 
			component: () => import('pages/user_profiles_2023_11_15/edit.vue'), 
			props: true 
		},

//user_sims routes
		{
			path: '/user_sims/:fieldName?/:fieldValue?',
			name: 'user_simslist',
			component: () => import('pages/user_sims/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/user_sims/view/:id', 
			name: 'user_simsview', 
			component: () => import('pages/user_sims/view.vue'), 
			props: true 
		},
		{ 
			path: '/user_sims/add', 
			name: 'user_simsadd', 
			component: () => import('pages/user_sims/add.vue'), 
			props: true 
		},
		{ 
			path: '/user_sims/edit/:id', 
			name: 'user_simsedit', 
			component: () => import('pages/user_sims/edit.vue'), 
			props: true 
		},

//useractive routes

//usercards routes

//usercrmroles routes

//usercrms routes

//userktaall routes

//userotps routes

//userprofiles routes
		{
			path: '/userprofiles/:fieldName?/:fieldValue?',
			name: 'userprofileslist',
			component: () => import('pages/userprofiles/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/userprofiles/view/:id', 
			name: 'userprofilesview', 
			component: () => import('pages/userprofiles/view.vue'), 
			props: true 
		},
		{ 
			path: '/userprofiles/add', 
			name: 'userprofilesadd', 
			component: () => import('pages/userprofiles/add.vue'), 
			props: true 
		},
		{ 
			path: '/userprofiles/edit/:id', 
			name: 'userprofilesedit', 
			component: () => import('pages/userprofiles/edit.vue'), 
			props: true 
		},

//userprofiles20230601 routes

//userprofiles20230619 routes

//userprofiles20230913 routes

//users routes
		{
			path: '/users/:fieldName?/:fieldValue?',
			name: 'userslist',
			component: () => import('pages/users/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users/view/:id', 
			name: 'usersview', 
			component: () => import('pages/users/view.vue'), 
			props: true 
		},
		{ 
			path: '/users/add', 
			name: 'usersadd', 
			component: () => import('pages/users/add.vue'), 
			props: true 
		},
		{ 
			path: '/users/edit/:id', 
			name: 'usersedit', 
			component: () => import('pages/users/edit.vue'), 
			props: true 
		},

//users_2023_06_01 routes
		{
			path: '/users_2023_06_01/:fieldName?/:fieldValue?',
			name: 'users_2023_06_01list',
			component: () => import('pages/users_2023_06_01/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users_2023_06_01/view/:id', 
			name: 'users_2023_06_01view', 
			component: () => import('pages/users_2023_06_01/view.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_01/add', 
			name: 'users_2023_06_01add', 
			component: () => import('pages/users_2023_06_01/add.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_01/edit/:id', 
			name: 'users_2023_06_01edit', 
			component: () => import('pages/users_2023_06_01/edit.vue'), 
			props: true 
		},

//users_2023_06_01 routes
		{
			path: '/users_2023_06_01/:fieldName?/:fieldValue?',
			name: 'users_2023_06_01list',
			component: () => import('pages/users_2023_06_01/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users_2023_06_01/view/:id', 
			name: 'users_2023_06_01view', 
			component: () => import('pages/users_2023_06_01/view.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_01/add', 
			name: 'users_2023_06_01add', 
			component: () => import('pages/users_2023_06_01/add.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_01/edit/:id', 
			name: 'users_2023_06_01edit', 
			component: () => import('pages/users_2023_06_01/edit.vue'), 
			props: true 
		},

//users_2023_06_16 routes
		{
			path: '/users_2023_06_16/:fieldName?/:fieldValue?',
			name: 'users_2023_06_16list',
			component: () => import('pages/users_2023_06_16/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users_2023_06_16/view/:id', 
			name: 'users_2023_06_16view', 
			component: () => import('pages/users_2023_06_16/view.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_16/add', 
			name: 'users_2023_06_16add', 
			component: () => import('pages/users_2023_06_16/add.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_16/edit/:id', 
			name: 'users_2023_06_16edit', 
			component: () => import('pages/users_2023_06_16/edit.vue'), 
			props: true 
		},

//users_2023_06_16 routes
		{
			path: '/users_2023_06_16/:fieldName?/:fieldValue?',
			name: 'users_2023_06_16list',
			component: () => import('pages/users_2023_06_16/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users_2023_06_16/view/:id', 
			name: 'users_2023_06_16view', 
			component: () => import('pages/users_2023_06_16/view.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_16/add', 
			name: 'users_2023_06_16add', 
			component: () => import('pages/users_2023_06_16/add.vue'), 
			props: true 
		},
		{ 
			path: '/users_2023_06_16/edit/:id', 
			name: 'users_2023_06_16edit', 
			component: () => import('pages/users_2023_06_16/edit.vue'), 
			props: true 
		},

//users_copy_20230913 routes
		{
			path: '/users_copy_20230913/:fieldName?/:fieldValue?',
			name: 'users_copy_20230913list',
			component: () => import('pages/users_copy_20230913/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/users_copy_20230913/view/:id', 
			name: 'users_copy_20230913view', 
			component: () => import('pages/users_copy_20230913/view.vue'), 
			props: true 
		},
		{ 
			path: '/users_copy_20230913/add', 
			name: 'users_copy_20230913add', 
			component: () => import('pages/users_copy_20230913/add.vue'), 
			props: true 
		},
		{ 
			path: '/users_copy_20230913/edit/:id', 
			name: 'users_copy_20230913edit', 
			component: () => import('pages/users_copy_20230913/edit.vue'), 
			props: true 
		},

//users20230601 routes

//users20230616 routes

//userscopy20230913 routes

//usersims routes

//v_club_status routes
		{
			path: '/v_club_status/:fieldName?/:fieldValue?',
			name: 'v_club_statuslist',
			component: () => import('pages/v_club_status/list.vue'),
			props: route => passRouteToProps(route)
		},

//v_daerah routes
		{
			path: '/v_daerah/:fieldName?/:fieldValue?',
			name: 'v_daerahlist',
			component: () => import('pages/v_daerah/list.vue'),
			props: route => passRouteToProps(route)
		},

//v_kis_status routes
		{
			path: '/v_kis_status/:fieldName?/:fieldValue?',
			name: 'v_kis_statuslist',
			component: () => import('pages/v_kis_status/list.vue'),
			props: route => passRouteToProps(route)
		},

//v_kta_status routes
		{
			path: '/v_kta_status/:fieldName?/:fieldValue?',
			name: 'v_kta_statuslist',
			component: () => import('pages/v_kta_status/list.vue'),
			props: route => passRouteToProps(route)
		},

//v_report_cetak_tkt routes
		{
			path: '/v_report_cetak_tkt/:fieldName?/:fieldValue?',
			name: 'v_report_cetak_tktlist',
			component: () => import('pages/v_report_cetak_tkt/list.vue'),
			props: route => passRouteToProps(route)
		},

//vclubstatus routes

//vdaerah routes

//vendors routes

//vkisstatus routes

//vktastatus routes

//wards routes
		{
			path: '/wards/:fieldName?/:fieldValue?',
			name: 'wardslist',
			component: () => import('pages/wards/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/wards/view/:id', 
			name: 'wardsview', 
			component: () => import('pages/wards/view.vue'), 
			props: true 
		},
		{ 
			path: '/wards/add', 
			name: 'wardsadd', 
			component: () => import('pages/wards/add.vue'), 
			props: true 
		},
		{ 
			path: '/wards/edit/:id', 
			name: 'wardsedit', 
			component: () => import('pages/wards/edit.vue'), 
			props: true 
		},

//welcome_banner routes
		{
			path: '/welcome_banner/:fieldName?/:fieldValue?',
			name: 'welcome_bannerlist',
			component: () => import('pages/welcome_banner/list.vue'),
			props: route => passRouteToProps(route)
		},

//welcome_berita routes
		{
			path: '/welcome_berita/:fieldName?/:fieldValue?',
			name: 'welcome_beritalist',
			component: () => import('pages/welcome_berita/list.vue'),
			props: route => passRouteToProps(route)
		},
		{ 
			path: '/welcome_berita/view/:id', 
			name: 'welcome_beritaview', 
			component: () => import('pages/welcome_berita/view.vue'), 
			props: true 
		},
		{ 
			path: '/welcome_berita/add', 
			name: 'welcome_beritaadd', 
			component: () => import('pages/welcome_berita/add.vue'), 
			props: true 
		},
		{ 
			path: '/welcome_berita/edit/:id', 
			name: 'welcome_beritaedit', 
			component: () => import('pages/welcome_berita/edit.vue'), 
			props: true 
		},

		
		
		
		

		//Error pages
		{ path:  '/error/forbidden', name: 'forbidden',  component: () => import('pages/errors/forbidden.vue') },
		{ path: '/error/notfound',  name: 'notfound', component: () => import('pages/errors/pagenotfound.vue') }
	],
	
	},
	{ path: '/:catchAll(.*)*', component: () => import('pages/errors/pagenotfound.vue') }
];

export default route(async function () {
  const createHistory = process.env.SERVER
    ? createMemoryHistory
    : (process.env.VUE_ROUTER_MODE == 'history' ? createWebHistory : createWebHashHistory)
	
	const mainRoute = routes.find(x => x.name = "main");
	
	
	const Router = createRouter({
	scrollBehavior(to, from, savedPostion){
		if(savedPostion) return savedPostion;
		return { top:0 }
	},
    routes,
    // Leave this as is and make changes in quasar.conf.js instead!
    // quasar.conf.js -> build -> vueRouterMode
    // quasar.conf.js -> build -> publicPath
    history: createHistory(process.env.VUE_ROUTER_BASE)
  })
  return Router
})

