
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "path": "/home",
    "label": "Home",
    "icon": "airplay",
    "iconcolor": "grey-7",
    "target": "",
    "submenu": []
  },
  {
    "path": "/reportaldy",
    "label": "Membership Report",
    "icon": "assignment",
    "iconcolor": "grey-7",
    "target": "",
    "submenu": []
  }
],
	allaboutuserslistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Prop Club",
    "align": "left",
    "sortable": false,
    "name": "prop_club",
    "field": "prop_club"
  }
],
	alluserslistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Prop Club",
    "align": "left",
    "sortable": false,
    "name": "prop_club",
    "field": "prop_club"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  }
],
	appversionslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Android",
    "align": "left",
    "sortable": false,
    "name": "android",
    "field": "android"
  },
  {
    "label": "Iphone",
    "align": "left",
    "sortable": false,
    "name": "iphone",
    "field": "iphone"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	broadcastslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Crm Id",
    "align": "left",
    "sortable": false,
    "name": "user_crm_id",
    "field": "user_crm_id"
  },
  {
    "label": "Province Type",
    "align": "left",
    "sortable": false,
    "name": "province_type",
    "field": "province_type"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Kta",
    "align": "left",
    "sortable": false,
    "name": "kta",
    "field": "kta"
  },
  {
    "label": "Title",
    "align": "left",
    "sortable": false,
    "name": "title",
    "field": "title"
  },
  {
    "label": "Body",
    "align": "left",
    "sortable": false,
    "name": "body",
    "field": "body"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Total User",
    "align": "left",
    "sortable": false,
    "name": "total_user",
    "field": "total_user"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	bundlingorderslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Bundling Id",
    "align": "left",
    "sortable": false,
    "name": "bundling_id",
    "field": "bundling_id"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Titla",
    "align": "left",
    "sortable": false,
    "name": "payment_titla",
    "field": "payment_titla"
  },
  {
    "label": "Payment Description",
    "align": "left",
    "sortable": false,
    "name": "payment_description",
    "field": "payment_description"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Fee",
    "align": "left",
    "sortable": false,
    "name": "fee",
    "field": "fee"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "Payment Channel Id",
    "align": "left",
    "sortable": false,
    "name": "payment_channel_id",
    "field": "payment_channel_id"
  },
  {
    "label": "Full Response",
    "align": "left",
    "sortable": false,
    "name": "full_response",
    "field": "full_response"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	bundlingslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Kta Id",
    "align": "left",
    "sortable": false,
    "name": "kta_id",
    "field": "kta_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Total Harga",
    "align": "left",
    "sortable": false,
    "name": "total_harga",
    "field": "total_harga"
  },
  {
    "label": "Start Date",
    "align": "left",
    "sortable": false,
    "name": "start_date",
    "field": "start_date"
  },
  {
    "label": "End Date",
    "align": "left",
    "sortable": false,
    "name": "end_date",
    "field": "end_date"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "On Register",
    "align": "left",
    "sortable": false,
    "name": "on_register",
    "field": "on_register"
  },
  {
    "label": "Diskon",
    "align": "left",
    "sortable": false,
    "name": "diskon",
    "field": "diskon"
  },
  {
    "label": "Remark",
    "align": "left",
    "sortable": false,
    "name": "remark",
    "field": "remark"
  },
  {
    "label": "Isdelete",
    "align": "left",
    "sortable": false,
    "name": "isdelete",
    "field": "isdelete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	calendereventcategorieslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	calendereventslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Title",
    "align": "left",
    "sortable": false,
    "name": "title",
    "field": "title"
  },
  {
    "label": "Date Start",
    "align": "left",
    "sortable": false,
    "name": "date_start",
    "field": "date_start"
  },
  {
    "label": "Date End",
    "align": "left",
    "sortable": false,
    "name": "date_end",
    "field": "date_end"
  },
  {
    "label": "Location Name",
    "align": "left",
    "sortable": false,
    "name": "location_name",
    "field": "location_name"
  },
  {
    "label": "Location",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Latitude",
    "align": "left",
    "sortable": false,
    "name": "latitude",
    "field": "latitude"
  },
  {
    "label": "Longitude",
    "align": "left",
    "sortable": false,
    "name": "longitude",
    "field": "longitude"
  },
  {
    "label": "File Banner",
    "align": "left",
    "sortable": false,
    "name": "file_banner",
    "field": "file_banner"
  },
  {
    "label": "File Denah",
    "align": "left",
    "sortable": false,
    "name": "file_denah",
    "field": "file_denah"
  },
  {
    "label": "Spesifikasi",
    "align": "left",
    "sortable": false,
    "name": "spesifikasi",
    "field": "spesifikasi"
  },
  {
    "label": "Kontak",
    "align": "left",
    "sortable": false,
    "name": "kontak",
    "field": "kontak"
  },
  {
    "label": "Link Web",
    "align": "left",
    "sortable": false,
    "name": "link_web",
    "field": "link_web"
  },
  {
    "label": "Link Ticket",
    "align": "left",
    "sortable": false,
    "name": "link_ticket",
    "field": "link_ticket"
  },
  {
    "label": "Link Map",
    "align": "left",
    "sortable": false,
    "name": "link_map",
    "field": "link_map"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Calender Event Category Id",
    "align": "left",
    "sortable": false,
    "name": "calender_event_category_id",
    "field": "calender_event_category_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	cardlogslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id Crm",
    "align": "left",
    "sortable": false,
    "name": "user_id_crm",
    "field": "user_id_crm"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "File S3",
    "align": "left",
    "sortable": false,
    "name": "file_s3",
    "field": "file_s3"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	cekclubpropinsilistheader: [
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  }
],
	cekduplicateclublistheader: [
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Duplikat",
    "align": "left",
    "sortable": false,
    "name": "duplikat",
    "field": "duplikat"
  }
],
	cekktaagneslistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Namamember",
    "align": "left",
    "sortable": false,
    "name": "namamember",
    "field": "namamember"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Kota",
    "align": "left",
    "sortable": false,
    "name": "kota",
    "field": "kota"
  },
  {
    "label": "Provinsi",
    "align": "left",
    "sortable": false,
    "name": "provinsi",
    "field": "provinsi"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Date Exp",
    "align": "left",
    "sortable": false,
    "name": "date_exp",
    "field": "date_exp"
  },
  {
    "label": "Enrollment",
    "align": "left",
    "sortable": false,
    "name": "enrollment",
    "field": "enrollment"
  },
  {
    "label": "Status Order",
    "align": "left",
    "sortable": false,
    "name": "status_order",
    "field": "status_order"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Uuid Pl",
    "align": "left",
    "sortable": false,
    "name": "uuid_pl",
    "field": "uuid_pl"
  },
  {
    "label": "Payment Title",
    "align": "left",
    "sortable": false,
    "name": "payment_title",
    "field": "payment_title"
  }
],
	cekktacrmlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Id User",
    "align": "left",
    "sortable": false,
    "name": "id_user",
    "field": "id_user"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "Userid",
    "align": "left",
    "sortable": false,
    "name": "userid",
    "field": "userid"
  },
  {
    "label": "Useruuid",
    "align": "left",
    "sortable": false,
    "name": "useruuid",
    "field": "useruuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Iscetak",
    "align": "left",
    "sortable": false,
    "name": "iscetak",
    "field": "iscetak"
  },
  {
    "label": "Idnya",
    "align": "left",
    "sortable": false,
    "name": "idnya",
    "field": "idnya"
  },
  {
    "label": "Cetak Date",
    "align": "left",
    "sortable": false,
    "name": "cetak_date",
    "field": "cetak_date"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Orders",
    "align": "left",
    "sortable": false,
    "name": "orders",
    "field": "orders"
  },
  {
    "label": "Uid",
    "align": "left",
    "sortable": false,
    "name": "uid",
    "field": "uid"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Date Exp",
    "align": "left",
    "sortable": false,
    "name": "date_exp",
    "field": "date_exp"
  },
  {
    "label": "Namamember",
    "align": "left",
    "sortable": false,
    "name": "namamember",
    "field": "namamember"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Kis",
    "align": "left",
    "sortable": false,
    "name": "kis",
    "field": "kis"
  },
  {
    "label": "Kis Uid",
    "align": "left",
    "sortable": false,
    "name": "kis_uid",
    "field": "kis_uid"
  }
],
	cekktavsclublistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Club Default",
    "align": "left",
    "sortable": false,
    "name": "club_default",
    "field": "club_default"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Nama Club",
    "align": "left",
    "sortable": false,
    "name": "nama_club",
    "field": "nama_club"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  }
],
	cekstatusktalistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  }
],
	cekuseerdoubleclublistheader: [
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  }
],
	changelogslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Modul",
    "align": "left",
    "sortable": false,
    "name": "modul",
    "field": "modul"
  },
  {
    "label": "Metod",
    "align": "left",
    "sortable": false,
    "name": "metod",
    "field": "metod"
  },
  {
    "label": "User Crm Id",
    "align": "left",
    "sortable": false,
    "name": "user_crm_id",
    "field": "user_crm_id"
  },
  {
    "label": "Before Chages",
    "align": "left",
    "sortable": false,
    "name": "before_chages",
    "field": "before_chages"
  },
  {
    "label": "After Changes",
    "align": "left",
    "sortable": false,
    "name": "after_changes",
    "field": "after_changes"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubclaimlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Art Document",
    "align": "left",
    "sortable": false,
    "name": "art_document",
    "field": "art_document"
  },
  {
    "label": "Cert Number",
    "align": "left",
    "sortable": false,
    "name": "cert_number",
    "field": "cert_number"
  },
  {
    "label": "Cert Document",
    "align": "left",
    "sortable": false,
    "name": "cert_document",
    "field": "cert_document"
  },
  {
    "label": "Additional Document",
    "align": "left",
    "sortable": false,
    "name": "additional_document",
    "field": "additional_document"
  },
  {
    "label": "Logo Name",
    "align": "left",
    "sortable": false,
    "name": "logo_name",
    "field": "logo_name"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Club Privacy",
    "align": "left",
    "sortable": false,
    "name": "club_privacy",
    "field": "club_privacy"
  },
  {
    "label": "Club Category",
    "align": "left",
    "sortable": false,
    "name": "club_category",
    "field": "club_category"
  },
  {
    "label": "External Link",
    "align": "left",
    "sortable": false,
    "name": "external_link",
    "field": "external_link"
  },
  {
    "label": "Catatan",
    "align": "left",
    "sortable": false,
    "name": "catatan",
    "field": "catatan"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Kodepos",
    "align": "left",
    "sortable": false,
    "name": "kodepos",
    "field": "kodepos"
  },
  {
    "label": "Bank Id",
    "align": "left",
    "sortable": false,
    "name": "bank_id",
    "field": "bank_id"
  },
  {
    "label": "Akun No",
    "align": "left",
    "sortable": false,
    "name": "akun_no",
    "field": "akun_no"
  },
  {
    "label": "Akun Nama",
    "align": "left",
    "sortable": false,
    "name": "akun_nama",
    "field": "akun_nama"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Claimer Uuid",
    "align": "left",
    "sortable": false,
    "name": "claimer_uuid",
    "field": "claimer_uuid"
  },
  {
    "label": "Bidang",
    "align": "left",
    "sortable": false,
    "name": "bidang",
    "field": "bidang"
  },
  {
    "label": "Tanggal Berdiri",
    "align": "left",
    "sortable": false,
    "name": "tanggal_berdiri",
    "field": "tanggal_berdiri"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubdetailslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Bank",
    "align": "left",
    "sortable": false,
    "name": "bank",
    "field": "bank"
  },
  {
    "label": "Bank Branch",
    "align": "left",
    "sortable": false,
    "name": "bank_branch",
    "field": "bank_branch"
  },
  {
    "label": "Bank Holder Name",
    "align": "left",
    "sortable": false,
    "name": "bank_holder_name",
    "field": "bank_holder_name"
  },
  {
    "label": "Bank Account Number",
    "align": "left",
    "sortable": false,
    "name": "bank_account_number",
    "field": "bank_account_number"
  },
  {
    "label": "File Document",
    "align": "left",
    "sortable": false,
    "name": "file_document",
    "field": "file_document"
  },
  {
    "label": "Document Approve",
    "align": "left",
    "sortable": false,
    "name": "document_approve",
    "field": "document_approve"
  },
  {
    "label": "User Id Created",
    "align": "left",
    "sortable": false,
    "name": "user_id_created",
    "field": "user_id_created"
  },
  {
    "label": "User Crm Id Approved",
    "align": "left",
    "sortable": false,
    "name": "user_crm_id_approved",
    "field": "user_crm_id_approved"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubmemberslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Club Membership Id",
    "align": "left",
    "sortable": false,
    "name": "club_membership_id",
    "field": "club_membership_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  }
],
	clubmembershiplistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "External Link",
    "align": "left",
    "sortable": false,
    "name": "external_link",
    "field": "external_link"
  },
  {
    "label": "Club Category",
    "align": "left",
    "sortable": false,
    "name": "club_category",
    "field": "club_category"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Legacy Id",
    "align": "left",
    "sortable": false,
    "name": "legacy_id",
    "field": "legacy_id"
  },
  {
    "label": "Art Documents Approval",
    "align": "left",
    "sortable": false,
    "name": "art_documents_approval",
    "field": "art_documents_approval"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw Number",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_number",
    "field": "rt_rw_number"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Post Code",
    "align": "left",
    "sortable": false,
    "name": "post_code",
    "field": "post_code"
  },
  {
    "label": "Bank Holder Name",
    "align": "left",
    "sortable": false,
    "name": "bank_holder_name",
    "field": "bank_holder_name"
  },
  {
    "label": "Bank Number",
    "align": "left",
    "sortable": false,
    "name": "bank_number",
    "field": "bank_number"
  },
  {
    "label": "Art Documents",
    "align": "left",
    "sortable": false,
    "name": "art_documents",
    "field": "art_documents"
  },
  {
    "label": "Cert Number",
    "align": "left",
    "sortable": false,
    "name": "cert_number",
    "field": "cert_number"
  },
  {
    "label": "Cert Documents",
    "align": "left",
    "sortable": false,
    "name": "cert_documents",
    "field": "cert_documents"
  },
  {
    "label": "Additional Documents",
    "align": "left",
    "sortable": false,
    "name": "additional_documents",
    "field": "additional_documents"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Club Type",
    "align": "left",
    "sortable": false,
    "name": "club_type",
    "field": "club_type"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Old Club Code",
    "align": "left",
    "sortable": false,
    "name": "old_club_code",
    "field": "old_club_code"
  },
  {
    "label": "Bank Id",
    "align": "left",
    "sortable": false,
    "name": "bank_id",
    "field": "bank_id"
  },
  {
    "label": "Club Code",
    "align": "left",
    "sortable": false,
    "name": "club_code",
    "field": "club_code"
  },
  {
    "label": "Club Status",
    "align": "left",
    "sortable": false,
    "name": "club_status",
    "field": "club_status"
  },
  {
    "label": "Avatar Url",
    "align": "left",
    "sortable": false,
    "name": "avatar_url",
    "field": "avatar_url"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Club Privacy",
    "align": "left",
    "sortable": false,
    "name": "club_privacy",
    "field": "club_privacy"
  },
  {
    "label": "Claimed",
    "align": "left",
    "sortable": false,
    "name": "claimed",
    "field": "claimed"
  },
  {
    "label": "Auto Approve",
    "align": "left",
    "sortable": false,
    "name": "auto_approve",
    "field": "auto_approve"
  },
  {
    "label": "Security Code",
    "align": "left",
    "sortable": false,
    "name": "security_code",
    "field": "security_code"
  },
  {
    "label": "Member No",
    "align": "left",
    "sortable": false,
    "name": "member_no",
    "field": "member_no"
  },
  {
    "label": "Certificate Print",
    "align": "left",
    "sortable": false,
    "name": "certificate_print",
    "field": "certificate_print"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubmembershippackageslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Club Membership Id",
    "align": "left",
    "sortable": false,
    "name": "club_membership_id",
    "field": "club_membership_id"
  },
  {
    "label": "Package Id",
    "align": "left",
    "sortable": false,
    "name": "package_id",
    "field": "package_id"
  },
  {
    "label": "Package Status",
    "align": "left",
    "sortable": false,
    "name": "package_status",
    "field": "package_status"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Last Enrolled Date",
    "align": "left",
    "sortable": false,
    "name": "last_enrolled_date",
    "field": "last_enrolled_date"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubroleslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Title",
    "align": "left",
    "sortable": false,
    "name": "title",
    "field": "title"
  },
  {
    "label": "Is Bom",
    "align": "left",
    "sortable": false,
    "name": "is_bom",
    "field": "is_bom"
  },
  {
    "label": "Display Order",
    "align": "left",
    "sortable": false,
    "name": "display_order",
    "field": "display_order"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubuserrequestslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "User Approve",
    "align": "left",
    "sortable": false,
    "name": "user_approve",
    "field": "user_approve"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "User Club Id",
    "align": "left",
    "sortable": false,
    "name": "user_club_id",
    "field": "user_club_id"
  },
  {
    "label": "User Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_club_role_id",
    "field": "user_club_role_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubuserslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubmembershiplistheader2: [
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Art Documents Approval",
    "align": "left",
    "sortable": false,
    "name": "art_documents_approval",
    "field": "art_documents_approval"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Bank Id",
    "align": "left",
    "sortable": false,
    "name": "bank_id",
    "field": "bank_id"
  },
  {
    "label": "Claimed",
    "align": "left",
    "sortable": false,
    "name": "claimed",
    "field": "claimed"
  },
  {
    "label": "Auto Approve",
    "align": "left",
    "sortable": false,
    "name": "auto_approve",
    "field": "auto_approve"
  },
  {
    "label": "Member No",
    "align": "left",
    "sortable": false,
    "name": "member_no",
    "field": "member_no"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Bank Number",
    "align": "left",
    "sortable": false,
    "name": "bank_number",
    "field": "bank_number"
  },
  {
    "label": "Art Documents",
    "align": "left",
    "sortable": false,
    "name": "art_documents",
    "field": "art_documents"
  },
  {
    "label": "Cert Number",
    "align": "left",
    "sortable": false,
    "name": "cert_number",
    "field": "cert_number"
  },
  {
    "label": "Cert Documents",
    "align": "left",
    "sortable": false,
    "name": "cert_documents",
    "field": "cert_documents"
  },
  {
    "label": "Additional Documents",
    "align": "left",
    "sortable": false,
    "name": "additional_documents",
    "field": "additional_documents"
  },
  {
    "label": "Club Privacy",
    "align": "left",
    "sortable": false,
    "name": "club_privacy",
    "field": "club_privacy"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Security Code",
    "align": "left",
    "sortable": false,
    "name": "security_code",
    "field": "security_code"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Club Type",
    "align": "left",
    "sortable": false,
    "name": "club_type",
    "field": "club_type"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "External Link",
    "align": "left",
    "sortable": false,
    "name": "external_link",
    "field": "external_link"
  },
  {
    "label": "Club Category",
    "align": "left",
    "sortable": false,
    "name": "club_category",
    "field": "club_category"
  },
  {
    "label": "Old Club Code",
    "align": "left",
    "sortable": false,
    "name": "old_club_code",
    "field": "old_club_code"
  },
  {
    "label": "Legacy Id",
    "align": "left",
    "sortable": false,
    "name": "legacy_id",
    "field": "legacy_id"
  },
  {
    "label": "Certificate Print",
    "align": "left",
    "sortable": false,
    "name": "certificate_print",
    "field": "certificate_print"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw Number",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_number",
    "field": "rt_rw_number"
  },
  {
    "label": "Club Code",
    "align": "left",
    "sortable": false,
    "name": "club_code",
    "field": "club_code"
  },
  {
    "label": "Club Status",
    "align": "left",
    "sortable": false,
    "name": "club_status",
    "field": "club_status"
  },
  {
    "label": "Avatar Url",
    "align": "left",
    "sortable": false,
    "name": "avatar_url",
    "field": "avatar_url"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Post Code",
    "align": "left",
    "sortable": false,
    "name": "post_code",
    "field": "post_code"
  },
  {
    "label": "Bank Holder Name",
    "align": "left",
    "sortable": false,
    "name": "bank_holder_name",
    "field": "bank_holder_name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubmembershippackageslistheader2: [
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Club Membership Id",
    "align": "left",
    "sortable": false,
    "name": "club_membership_id",
    "field": "club_membership_id"
  },
  {
    "label": "Package Id",
    "align": "left",
    "sortable": false,
    "name": "package_id",
    "field": "package_id"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Last Enrolled Date",
    "align": "left",
    "sortable": false,
    "name": "last_enrolled_date",
    "field": "last_enrolled_date"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Package Status",
    "align": "left",
    "sortable": false,
    "name": "package_status",
    "field": "package_status"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubslistheader: [
  {
    "label": "Bidang",
    "align": "left",
    "sortable": false,
    "name": "bidang",
    "field": "bidang"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Activated At",
    "align": "left",
    "sortable": false,
    "name": "activated_at",
    "field": "activated_at"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Cetak At",
    "align": "left",
    "sortable": false,
    "name": "cetak_at",
    "field": "cetak_at"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Berdiri At",
    "align": "left",
    "sortable": false,
    "name": "berdiri_at",
    "field": "berdiri_at"
  },
  {
    "label": "Bank Id",
    "align": "left",
    "sortable": false,
    "name": "bank_id",
    "field": "bank_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Claimed",
    "align": "left",
    "sortable": false,
    "name": "claimed",
    "field": "claimed"
  },
  {
    "label": "Moderator Approve",
    "align": "left",
    "sortable": false,
    "name": "moderator_approve",
    "field": "moderator_approve"
  },
  {
    "label": "Instagram",
    "align": "left",
    "sortable": false,
    "name": "instagram",
    "field": "instagram"
  },
  {
    "label": "Catatan",
    "align": "left",
    "sortable": false,
    "name": "catatan",
    "field": "catatan"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "Legacy Id",
    "align": "left",
    "sortable": false,
    "name": "legacy_id",
    "field": "legacy_id"
  },
  {
    "label": "Logo Name",
    "align": "left",
    "sortable": false,
    "name": "logo_name",
    "field": "logo_name"
  },
  {
    "label": "Art Document",
    "align": "left",
    "sortable": false,
    "name": "art_document",
    "field": "art_document"
  },
  {
    "label": "External Link",
    "align": "left",
    "sortable": false,
    "name": "external_link",
    "field": "external_link"
  },
  {
    "label": "Twitter",
    "align": "left",
    "sortable": false,
    "name": "twitter",
    "field": "twitter"
  },
  {
    "label": "Akun Nama",
    "align": "left",
    "sortable": false,
    "name": "akun_nama",
    "field": "akun_nama"
  },
  {
    "label": "Akun No",
    "align": "left",
    "sortable": false,
    "name": "akun_no",
    "field": "akun_no"
  },
  {
    "label": "Cert Number",
    "align": "left",
    "sortable": false,
    "name": "cert_number",
    "field": "cert_number"
  },
  {
    "label": "Cert Document",
    "align": "left",
    "sortable": false,
    "name": "cert_document",
    "field": "cert_document"
  },
  {
    "label": "Additional Document",
    "align": "left",
    "sortable": false,
    "name": "additional_document",
    "field": "additional_document"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Club Type",
    "align": "left",
    "sortable": false,
    "name": "club_type",
    "field": "club_type"
  },
  {
    "label": "Old Club Code",
    "align": "left",
    "sortable": false,
    "name": "old_club_code",
    "field": "old_club_code"
  },
  {
    "label": "Club Code",
    "align": "left",
    "sortable": false,
    "name": "club_code",
    "field": "club_code"
  },
  {
    "label": "Club Privacy",
    "align": "left",
    "sortable": false,
    "name": "club_privacy",
    "field": "club_privacy"
  },
  {
    "label": "Security Code",
    "align": "left",
    "sortable": false,
    "name": "security_code",
    "field": "security_code"
  },
  {
    "label": "User Uuid Created",
    "align": "left",
    "sortable": false,
    "name": "user_uuid_created",
    "field": "user_uuid_created"
  },
  {
    "label": "User Crm Created",
    "align": "left",
    "sortable": false,
    "name": "user_crm_created",
    "field": "user_crm_created"
  },
  {
    "label": "User Crm Approved",
    "align": "left",
    "sortable": false,
    "name": "user_crm_approved",
    "field": "user_crm_approved"
  },
  {
    "label": "Whatsapp",
    "align": "left",
    "sortable": false,
    "name": "whatsapp",
    "field": "whatsapp"
  },
  {
    "label": "Telegram",
    "align": "left",
    "sortable": false,
    "name": "telegram",
    "field": "telegram"
  },
  {
    "label": "Art Status",
    "align": "left",
    "sortable": false,
    "name": "art_status",
    "field": "art_status"
  },
  {
    "label": "Alasan Tolak",
    "align": "left",
    "sortable": false,
    "name": "alasan_tolak",
    "field": "alasan_tolak"
  },
  {
    "label": "Art Konfirmasi Oleh",
    "align": "left",
    "sortable": false,
    "name": "art_konfirmasi_oleh",
    "field": "art_konfirmasi_oleh"
  },
  {
    "label": "Claim Token",
    "align": "left",
    "sortable": false,
    "name": "claim_token",
    "field": "claim_token"
  },
  {
    "label": "Signature",
    "align": "left",
    "sortable": false,
    "name": "signature",
    "field": "signature"
  },
  {
    "label": "Merk",
    "align": "left",
    "sortable": false,
    "name": "merk",
    "field": "merk"
  },
  {
    "label": "Tanggal Pencetusan",
    "align": "left",
    "sortable": false,
    "name": "tanggal_pencetusan",
    "field": "tanggal_pencetusan"
  },
  {
    "label": "Nama Atpm",
    "align": "left",
    "sortable": false,
    "name": "nama_atpm",
    "field": "nama_atpm"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "Kota Penempatan",
    "align": "left",
    "sortable": false,
    "name": "kota_penempatan",
    "field": "kota_penempatan"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Tanggal Penempatan",
    "align": "left",
    "sortable": false,
    "name": "tanggal_penempatan",
    "field": "tanggal_penempatan"
  },
  {
    "label": "Nama Ketua",
    "align": "left",
    "sortable": false,
    "name": "nama_ketua",
    "field": "nama_ketua"
  },
  {
    "label": "Nama Sekretaris",
    "align": "left",
    "sortable": false,
    "name": "nama_sekretaris",
    "field": "nama_sekretaris"
  },
  {
    "label": "Nama Pic",
    "align": "left",
    "sortable": false,
    "name": "nama_pic",
    "field": "nama_pic"
  },
  {
    "label": "No Hp Pic",
    "align": "left",
    "sortable": false,
    "name": "no_hp_pic",
    "field": "no_hp_pic"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Kodepos",
    "align": "left",
    "sortable": false,
    "name": "kodepos",
    "field": "kodepos"
  },
  {
    "label": "Transaksi Id",
    "align": "left",
    "sortable": false,
    "name": "transaksi_id",
    "field": "transaksi_id"
  },
  {
    "label": "Pembaruan",
    "align": "left",
    "sortable": false,
    "name": "pembaruan",
    "field": "pembaruan"
  },
  {
    "label": "Email Pic",
    "align": "left",
    "sortable": false,
    "name": "email_pic",
    "field": "email_pic"
  },
  {
    "label": "Facebook",
    "align": "left",
    "sortable": false,
    "name": "facebook",
    "field": "facebook"
  },
  {
    "label": "Art Upload At",
    "align": "left",
    "sortable": false,
    "name": "art_upload_at",
    "field": "art_upload_at"
  },
  {
    "label": "Is Privater",
    "align": "left",
    "sortable": false,
    "name": "is_privater",
    "field": "is_privater"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubscopy20230913listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Activated At",
    "align": "left",
    "sortable": false,
    "name": "activated_at",
    "field": "activated_at"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Kodepos",
    "align": "left",
    "sortable": false,
    "name": "kodepos",
    "field": "kodepos"
  },
  {
    "label": "Transaksi Id",
    "align": "left",
    "sortable": false,
    "name": "transaksi_id",
    "field": "transaksi_id"
  },
  {
    "label": "Pembaruan",
    "align": "left",
    "sortable": false,
    "name": "pembaruan",
    "field": "pembaruan"
  },
  {
    "label": "Cetak At",
    "align": "left",
    "sortable": false,
    "name": "cetak_at",
    "field": "cetak_at"
  },
  {
    "label": "Bidang",
    "align": "left",
    "sortable": false,
    "name": "bidang",
    "field": "bidang"
  },
  {
    "label": "Berdiri At",
    "align": "left",
    "sortable": false,
    "name": "berdiri_at",
    "field": "berdiri_at"
  },
  {
    "label": "Catatan",
    "align": "left",
    "sortable": false,
    "name": "catatan",
    "field": "catatan"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "Legacy Id",
    "align": "left",
    "sortable": false,
    "name": "legacy_id",
    "field": "legacy_id"
  },
  {
    "label": "Logo Name",
    "align": "left",
    "sortable": false,
    "name": "logo_name",
    "field": "logo_name"
  },
  {
    "label": "Art Document",
    "align": "left",
    "sortable": false,
    "name": "art_document",
    "field": "art_document"
  },
  {
    "label": "External Link",
    "align": "left",
    "sortable": false,
    "name": "external_link",
    "field": "external_link"
  },
  {
    "label": "Bank Id",
    "align": "left",
    "sortable": false,
    "name": "bank_id",
    "field": "bank_id"
  },
  {
    "label": "Akun Nama",
    "align": "left",
    "sortable": false,
    "name": "akun_nama",
    "field": "akun_nama"
  },
  {
    "label": "Akun No",
    "align": "left",
    "sortable": false,
    "name": "akun_no",
    "field": "akun_no"
  },
  {
    "label": "Cert Number",
    "align": "left",
    "sortable": false,
    "name": "cert_number",
    "field": "cert_number"
  },
  {
    "label": "Cert Document",
    "align": "left",
    "sortable": false,
    "name": "cert_document",
    "field": "cert_document"
  },
  {
    "label": "Additional Document",
    "align": "left",
    "sortable": false,
    "name": "additional_document",
    "field": "additional_document"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Club Type",
    "align": "left",
    "sortable": false,
    "name": "club_type",
    "field": "club_type"
  },
  {
    "label": "Old Club Code",
    "align": "left",
    "sortable": false,
    "name": "old_club_code",
    "field": "old_club_code"
  },
  {
    "label": "Club Code",
    "align": "left",
    "sortable": false,
    "name": "club_code",
    "field": "club_code"
  },
  {
    "label": "Club Privacy",
    "align": "left",
    "sortable": false,
    "name": "club_privacy",
    "field": "club_privacy"
  },
  {
    "label": "Security Code",
    "align": "left",
    "sortable": false,
    "name": "security_code",
    "field": "security_code"
  },
  {
    "label": "User Uuid Created",
    "align": "left",
    "sortable": false,
    "name": "user_uuid_created",
    "field": "user_uuid_created"
  },
  {
    "label": "User Crm Created",
    "align": "left",
    "sortable": false,
    "name": "user_crm_created",
    "field": "user_crm_created"
  },
  {
    "label": "User Crm Approved",
    "align": "left",
    "sortable": false,
    "name": "user_crm_approved",
    "field": "user_crm_approved"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Claimed",
    "align": "left",
    "sortable": false,
    "name": "claimed",
    "field": "claimed"
  },
  {
    "label": "Claim Token",
    "align": "left",
    "sortable": false,
    "name": "claim_token",
    "field": "claim_token"
  },
  {
    "label": "Signature",
    "align": "left",
    "sortable": false,
    "name": "signature",
    "field": "signature"
  },
  {
    "label": "Merk",
    "align": "left",
    "sortable": false,
    "name": "merk",
    "field": "merk"
  },
  {
    "label": "Tanggal Pencetusan",
    "align": "left",
    "sortable": false,
    "name": "tanggal_pencetusan",
    "field": "tanggal_pencetusan"
  },
  {
    "label": "Nama Atpm",
    "align": "left",
    "sortable": false,
    "name": "nama_atpm",
    "field": "nama_atpm"
  },
  {
    "label": "Kota Penempatan",
    "align": "left",
    "sortable": false,
    "name": "kota_penempatan",
    "field": "kota_penempatan"
  },
  {
    "label": "Tanggal Penempatan",
    "align": "left",
    "sortable": false,
    "name": "tanggal_penempatan",
    "field": "tanggal_penempatan"
  },
  {
    "label": "Nama Ketua",
    "align": "left",
    "sortable": false,
    "name": "nama_ketua",
    "field": "nama_ketua"
  },
  {
    "label": "Nama Sekretaris",
    "align": "left",
    "sortable": false,
    "name": "nama_sekretaris",
    "field": "nama_sekretaris"
  },
  {
    "label": "Nama Pic",
    "align": "left",
    "sortable": false,
    "name": "nama_pic",
    "field": "nama_pic"
  },
  {
    "label": "No Hp Pic",
    "align": "left",
    "sortable": false,
    "name": "no_hp_pic",
    "field": "no_hp_pic"
  },
  {
    "label": "Email Pic",
    "align": "left",
    "sortable": false,
    "name": "email_pic",
    "field": "email_pic"
  },
  {
    "label": "Facebook",
    "align": "left",
    "sortable": false,
    "name": "facebook",
    "field": "facebook"
  },
  {
    "label": "Instagram",
    "align": "left",
    "sortable": false,
    "name": "instagram",
    "field": "instagram"
  },
  {
    "label": "Twitter",
    "align": "left",
    "sortable": false,
    "name": "twitter",
    "field": "twitter"
  },
  {
    "label": "Whatsapp",
    "align": "left",
    "sortable": false,
    "name": "whatsapp",
    "field": "whatsapp"
  },
  {
    "label": "Telegram",
    "align": "left",
    "sortable": false,
    "name": "telegram",
    "field": "telegram"
  },
  {
    "label": "Art Status",
    "align": "left",
    "sortable": false,
    "name": "art_status",
    "field": "art_status"
  },
  {
    "label": "Alasan Tolak",
    "align": "left",
    "sortable": false,
    "name": "alasan_tolak",
    "field": "alasan_tolak"
  },
  {
    "label": "Art Konfirmasi Oleh",
    "align": "left",
    "sortable": false,
    "name": "art_konfirmasi_oleh",
    "field": "art_konfirmasi_oleh"
  },
  {
    "label": "Moderator Approve",
    "align": "left",
    "sortable": false,
    "name": "moderator_approve",
    "field": "moderator_approve"
  },
  {
    "label": "Art Upload At",
    "align": "left",
    "sortable": false,
    "name": "art_upload_at",
    "field": "art_upload_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubscopy20240315listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Activated At",
    "align": "left",
    "sortable": false,
    "name": "activated_at",
    "field": "activated_at"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Kodepos",
    "align": "left",
    "sortable": false,
    "name": "kodepos",
    "field": "kodepos"
  },
  {
    "label": "Transaksi Id",
    "align": "left",
    "sortable": false,
    "name": "transaksi_id",
    "field": "transaksi_id"
  },
  {
    "label": "Pembaruan",
    "align": "left",
    "sortable": false,
    "name": "pembaruan",
    "field": "pembaruan"
  },
  {
    "label": "Cetak At",
    "align": "left",
    "sortable": false,
    "name": "cetak_at",
    "field": "cetak_at"
  },
  {
    "label": "Bidang",
    "align": "left",
    "sortable": false,
    "name": "bidang",
    "field": "bidang"
  },
  {
    "label": "Berdiri At",
    "align": "left",
    "sortable": false,
    "name": "berdiri_at",
    "field": "berdiri_at"
  },
  {
    "label": "Catatan",
    "align": "left",
    "sortable": false,
    "name": "catatan",
    "field": "catatan"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "Legacy Id",
    "align": "left",
    "sortable": false,
    "name": "legacy_id",
    "field": "legacy_id"
  },
  {
    "label": "Logo Name",
    "align": "left",
    "sortable": false,
    "name": "logo_name",
    "field": "logo_name"
  },
  {
    "label": "Art Document",
    "align": "left",
    "sortable": false,
    "name": "art_document",
    "field": "art_document"
  },
  {
    "label": "External Link",
    "align": "left",
    "sortable": false,
    "name": "external_link",
    "field": "external_link"
  },
  {
    "label": "Bank Id",
    "align": "left",
    "sortable": false,
    "name": "bank_id",
    "field": "bank_id"
  },
  {
    "label": "Akun Nama",
    "align": "left",
    "sortable": false,
    "name": "akun_nama",
    "field": "akun_nama"
  },
  {
    "label": "Akun No",
    "align": "left",
    "sortable": false,
    "name": "akun_no",
    "field": "akun_no"
  },
  {
    "label": "Cert Number",
    "align": "left",
    "sortable": false,
    "name": "cert_number",
    "field": "cert_number"
  },
  {
    "label": "Cert Document",
    "align": "left",
    "sortable": false,
    "name": "cert_document",
    "field": "cert_document"
  },
  {
    "label": "Additional Document",
    "align": "left",
    "sortable": false,
    "name": "additional_document",
    "field": "additional_document"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Club Type",
    "align": "left",
    "sortable": false,
    "name": "club_type",
    "field": "club_type"
  },
  {
    "label": "Old Club Code",
    "align": "left",
    "sortable": false,
    "name": "old_club_code",
    "field": "old_club_code"
  },
  {
    "label": "Club Code",
    "align": "left",
    "sortable": false,
    "name": "club_code",
    "field": "club_code"
  },
  {
    "label": "Club Privacy",
    "align": "left",
    "sortable": false,
    "name": "club_privacy",
    "field": "club_privacy"
  },
  {
    "label": "Security Code",
    "align": "left",
    "sortable": false,
    "name": "security_code",
    "field": "security_code"
  },
  {
    "label": "User Uuid Created",
    "align": "left",
    "sortable": false,
    "name": "user_uuid_created",
    "field": "user_uuid_created"
  },
  {
    "label": "User Crm Created",
    "align": "left",
    "sortable": false,
    "name": "user_crm_created",
    "field": "user_crm_created"
  },
  {
    "label": "User Crm Approved",
    "align": "left",
    "sortable": false,
    "name": "user_crm_approved",
    "field": "user_crm_approved"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Claimed",
    "align": "left",
    "sortable": false,
    "name": "claimed",
    "field": "claimed"
  },
  {
    "label": "Claim Token",
    "align": "left",
    "sortable": false,
    "name": "claim_token",
    "field": "claim_token"
  },
  {
    "label": "Signature",
    "align": "left",
    "sortable": false,
    "name": "signature",
    "field": "signature"
  },
  {
    "label": "Merk",
    "align": "left",
    "sortable": false,
    "name": "merk",
    "field": "merk"
  },
  {
    "label": "Tanggal Pencetusan",
    "align": "left",
    "sortable": false,
    "name": "tanggal_pencetusan",
    "field": "tanggal_pencetusan"
  },
  {
    "label": "Nama Atpm",
    "align": "left",
    "sortable": false,
    "name": "nama_atpm",
    "field": "nama_atpm"
  },
  {
    "label": "Kota Penempatan",
    "align": "left",
    "sortable": false,
    "name": "kota_penempatan",
    "field": "kota_penempatan"
  },
  {
    "label": "Tanggal Penempatan",
    "align": "left",
    "sortable": false,
    "name": "tanggal_penempatan",
    "field": "tanggal_penempatan"
  },
  {
    "label": "Nama Ketua",
    "align": "left",
    "sortable": false,
    "name": "nama_ketua",
    "field": "nama_ketua"
  },
  {
    "label": "Nama Sekretaris",
    "align": "left",
    "sortable": false,
    "name": "nama_sekretaris",
    "field": "nama_sekretaris"
  },
  {
    "label": "Nama Pic",
    "align": "left",
    "sortable": false,
    "name": "nama_pic",
    "field": "nama_pic"
  },
  {
    "label": "No Hp Pic",
    "align": "left",
    "sortable": false,
    "name": "no_hp_pic",
    "field": "no_hp_pic"
  },
  {
    "label": "Email Pic",
    "align": "left",
    "sortable": false,
    "name": "email_pic",
    "field": "email_pic"
  },
  {
    "label": "Facebook",
    "align": "left",
    "sortable": false,
    "name": "facebook",
    "field": "facebook"
  },
  {
    "label": "Instagram",
    "align": "left",
    "sortable": false,
    "name": "instagram",
    "field": "instagram"
  },
  {
    "label": "Twitter",
    "align": "left",
    "sortable": false,
    "name": "twitter",
    "field": "twitter"
  },
  {
    "label": "Whatsapp",
    "align": "left",
    "sortable": false,
    "name": "whatsapp",
    "field": "whatsapp"
  },
  {
    "label": "Telegram",
    "align": "left",
    "sortable": false,
    "name": "telegram",
    "field": "telegram"
  },
  {
    "label": "Art Status",
    "align": "left",
    "sortable": false,
    "name": "art_status",
    "field": "art_status"
  },
  {
    "label": "Alasan Tolak",
    "align": "left",
    "sortable": false,
    "name": "alasan_tolak",
    "field": "alasan_tolak"
  },
  {
    "label": "Art Konfirmasi Oleh",
    "align": "left",
    "sortable": false,
    "name": "art_konfirmasi_oleh",
    "field": "art_konfirmasi_oleh"
  },
  {
    "label": "Moderator Approve",
    "align": "left",
    "sortable": false,
    "name": "moderator_approve",
    "field": "moderator_approve"
  },
  {
    "label": "Art Upload At",
    "align": "left",
    "sortable": false,
    "name": "art_upload_at",
    "field": "art_upload_at"
  },
  {
    "label": "Is Privater",
    "align": "left",
    "sortable": false,
    "name": "is_privater",
    "field": "is_privater"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	clubuserslistheader2: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmmenupermissionslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Crm Menu Id",
    "align": "left",
    "sortable": false,
    "name": "crm_menu_id",
    "field": "crm_menu_id"
  },
  {
    "label": "Crm Permission Id",
    "align": "left",
    "sortable": false,
    "name": "crm_permission_id",
    "field": "crm_permission_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmmenuslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Parent Id",
    "align": "left",
    "sortable": false,
    "name": "parent_id",
    "field": "parent_id"
  },
  {
    "label": "Url",
    "align": "left",
    "sortable": false,
    "name": "url",
    "field": "url"
  },
  {
    "label": "Icon",
    "align": "left",
    "sortable": false,
    "name": "icon",
    "field": "icon"
  },
  {
    "label": "Order",
    "align": "left",
    "sortable": false,
    "name": "order",
    "field": "order"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmpermissionslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmrolemenuslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Crm Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_crm_role_id",
    "field": "user_crm_role_id"
  },
  {
    "label": "Crm Menu Id",
    "align": "left",
    "sortable": false,
    "name": "crm_menu_id",
    "field": "crm_menu_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmrolepermissionslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Crm Role Id",
    "align": "left",
    "sortable": false,
    "name": "user_crm_role_id",
    "field": "user_crm_role_id"
  },
  {
    "label": "Crm Permission Id",
    "align": "left",
    "sortable": false,
    "name": "crm_permission_id",
    "field": "crm_permission_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmroleprovinceslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Role Id",
    "align": "left",
    "sortable": false,
    "name": "role_id",
    "field": "role_id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	crmusrlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User",
    "align": "left",
    "sortable": false,
    "name": "user",
    "field": "user"
  },
  {
    "label": "Pass",
    "align": "left",
    "sortable": false,
    "name": "pass",
    "field": "pass"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	ctcommentlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Post Id",
    "align": "left",
    "sortable": false,
    "name": "post_id",
    "field": "post_id"
  },
  {
    "label": "Parent Id",
    "align": "left",
    "sortable": false,
    "name": "parent_id",
    "field": "parent_id"
  },
  {
    "label": "Owner Id",
    "align": "left",
    "sortable": false,
    "name": "owner_id",
    "field": "owner_id"
  },
  {
    "label": "Owner Type",
    "align": "left",
    "sortable": false,
    "name": "owner_type",
    "field": "owner_type"
  },
  {
    "label": "Text",
    "align": "left",
    "sortable": false,
    "name": "text",
    "field": "text"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Report Status",
    "align": "left",
    "sortable": false,
    "name": "report_status",
    "field": "report_status"
  },
  {
    "label": "Report Reason",
    "align": "left",
    "sortable": false,
    "name": "report_reason",
    "field": "report_reason"
  }
],
	ctcommunitylistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Owner Id",
    "align": "left",
    "sortable": false,
    "name": "owner_id",
    "field": "owner_id"
  },
  {
    "label": "Image Url",
    "align": "left",
    "sortable": false,
    "name": "image_url",
    "field": "image_url"
  },
  {
    "label": "Avatar Url",
    "align": "left",
    "sortable": false,
    "name": "avatar_url",
    "field": "avatar_url"
  },
  {
    "label": "Follower No",
    "align": "left",
    "sortable": false,
    "name": "follower_no",
    "field": "follower_no"
  },
  {
    "label": "Member No",
    "align": "left",
    "sortable": false,
    "name": "member_no",
    "field": "member_no"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "Priority",
    "align": "left",
    "sortable": false,
    "name": "priority",
    "field": "priority"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Location",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Privacy",
    "align": "left",
    "sortable": false,
    "name": "privacy",
    "field": "privacy"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	ctpostlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Community Id",
    "align": "left",
    "sortable": false,
    "name": "community_id",
    "field": "community_id"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Text",
    "align": "left",
    "sortable": false,
    "name": "text",
    "field": "text"
  },
  {
    "label": "Text Color",
    "align": "left",
    "sortable": false,
    "name": "text_color",
    "field": "text_color"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Background Color",
    "align": "left",
    "sortable": false,
    "name": "background_color",
    "field": "background_color"
  },
  {
    "label": "Background Image",
    "align": "left",
    "sortable": false,
    "name": "background_image",
    "field": "background_image"
  },
  {
    "label": "Video",
    "align": "left",
    "sortable": false,
    "name": "video",
    "field": "video"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Privacy",
    "align": "left",
    "sortable": false,
    "name": "privacy",
    "field": "privacy"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Like Number",
    "align": "left",
    "sortable": false,
    "name": "like_number",
    "field": "like_number"
  }
],
	ctreactionlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Post Id",
    "align": "left",
    "sortable": false,
    "name": "post_id",
    "field": "post_id"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	ctcommunitylistheader2: [
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Follower No",
    "align": "left",
    "sortable": false,
    "name": "follower_no",
    "field": "follower_no"
  },
  {
    "label": "Member No",
    "align": "left",
    "sortable": false,
    "name": "member_no",
    "field": "member_no"
  },
  {
    "label": "Priority",
    "align": "left",
    "sortable": false,
    "name": "priority",
    "field": "priority"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Owner Id",
    "align": "left",
    "sortable": false,
    "name": "owner_id",
    "field": "owner_id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Location",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Privacy",
    "align": "left",
    "sortable": false,
    "name": "privacy",
    "field": "privacy"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Image Url",
    "align": "left",
    "sortable": false,
    "name": "image_url",
    "field": "image_url"
  },
  {
    "label": "Avatar Url",
    "align": "left",
    "sortable": false,
    "name": "avatar_url",
    "field": "avatar_url"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Owner Uuid",
    "align": "left",
    "sortable": false,
    "name": "owner_uuid",
    "field": "owner_uuid"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	dataktalistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "No Kta",
    "align": "left",
    "sortable": false,
    "name": "no_kta",
    "field": "no_kta"
  },
  {
    "label": "No Ponsel",
    "align": "left",
    "sortable": false,
    "name": "no_ponsel",
    "field": "no_ponsel"
  },
  {
    "label": "Nik",
    "align": "left",
    "sortable": false,
    "name": "nik",
    "field": "nik"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Alamat",
    "align": "left",
    "sortable": false,
    "name": "alamat",
    "field": "alamat"
  },
  {
    "label": "Kota",
    "align": "left",
    "sortable": false,
    "name": "kota",
    "field": "kota"
  },
  {
    "label": "Propinsi",
    "align": "left",
    "sortable": false,
    "name": "propinsi",
    "field": "propinsi"
  },
  {
    "label": "Jenis Kta",
    "align": "left",
    "sortable": false,
    "name": "jenis_kta",
    "field": "jenis_kta"
  },
  {
    "label": "Harga",
    "align": "left",
    "sortable": false,
    "name": "harga",
    "field": "harga"
  },
  {
    "label": "Klub",
    "align": "left",
    "sortable": false,
    "name": "klub",
    "field": "klub"
  },
  {
    "label": "Status Kta",
    "align": "left",
    "sortable": false,
    "name": "status_kta",
    "field": "status_kta"
  },
  {
    "label": "Jatuh Tempo",
    "align": "left",
    "sortable": false,
    "name": "jatuh_tempo",
    "field": "jatuh_tempo"
  }
],
	districtslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	fiturslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Manfaat",
    "align": "left",
    "sortable": false,
    "name": "manfaat",
    "field": "manfaat"
  },
  {
    "label": "Syarat Ketentuan",
    "align": "left",
    "sortable": false,
    "name": "syarat_ketentuan",
    "field": "syarat_ketentuan"
  },
  {
    "label": "File Banner",
    "align": "left",
    "sortable": false,
    "name": "file_banner",
    "field": "file_banner"
  },
  {
    "label": "Remark",
    "align": "left",
    "sortable": false,
    "name": "remark",
    "field": "remark"
  },
  {
    "label": "File Logo",
    "align": "left",
    "sortable": false,
    "name": "file_logo",
    "field": "file_logo"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	kendaraanproductslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Kendaraan Id",
    "align": "left",
    "sortable": false,
    "name": "kendaraan_id",
    "field": "kendaraan_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Order Id",
    "align": "left",
    "sortable": false,
    "name": "order_id",
    "field": "order_id"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Status Asuransi",
    "align": "left",
    "sortable": false,
    "name": "status_asuransi",
    "field": "status_asuransi"
  },
  {
    "label": "Keterangan Asuransi",
    "align": "left",
    "sortable": false,
    "name": "keterangan_asuransi",
    "field": "keterangan_asuransi"
  },
  {
    "label": "Tanggal Asuransi",
    "align": "left",
    "sortable": false,
    "name": "tanggal_asuransi",
    "field": "tanggal_asuransi"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Certificate No",
    "align": "left",
    "sortable": false,
    "name": "certificate_no",
    "field": "certificate_no"
  },
  {
    "label": "Certificate Url",
    "align": "left",
    "sortable": false,
    "name": "certificate_url",
    "field": "certificate_url"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	kiscategorylistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Code",
    "align": "left",
    "sortable": false,
    "name": "code",
    "field": "code"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Min Price",
    "align": "left",
    "sortable": false,
    "name": "min_price",
    "field": "min_price"
  },
  {
    "label": "Max Price",
    "align": "left",
    "sortable": false,
    "name": "max_price",
    "field": "max_price"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	kisprovincelistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Kis Id",
    "align": "left",
    "sortable": false,
    "name": "kis_id",
    "field": "kis_id"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  }
],
	kisuserslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Kis Province Id",
    "align": "left",
    "sortable": false,
    "name": "kis_province_id",
    "field": "kis_province_id"
  },
  {
    "label": "Enrolled Date",
    "align": "left",
    "sortable": false,
    "name": "enrolled_date",
    "field": "enrolled_date"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Blokir At",
    "align": "left",
    "sortable": false,
    "name": "blokir_at",
    "field": "blokir_at"
  },
  {
    "label": "Blokir Durasi",
    "align": "left",
    "sortable": false,
    "name": "blokir_durasi",
    "field": "blokir_durasi"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "Payment Expired",
    "align": "left",
    "sortable": false,
    "name": "payment_expired",
    "field": "payment_expired"
  },
  {
    "label": "Paid Info",
    "align": "left",
    "sortable": false,
    "name": "paid_info",
    "field": "paid_info"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	kisnyalistheader: [
  {
    "label": "Kis Uid",
    "align": "left",
    "sortable": false,
    "name": "kis_uid",
    "field": "kis_uid"
  },
  {
    "label": "Kis",
    "align": "left",
    "sortable": false,
    "name": "kis",
    "field": "kis"
  }
],
	ktalistheader: [
  {
    "label": "Nama",
    "align": "left",
    "sortable": false,
    "name": "nama",
    "field": "nama"
  },
  {
    "label": "Nik",
    "align": "left",
    "sortable": false,
    "name": "nik",
    "field": "nik"
  },
  {
    "label": "No Ponsel",
    "align": "left",
    "sortable": false,
    "name": "no_ponsel",
    "field": "no_ponsel"
  },
  {
    "label": "No Kta",
    "align": "left",
    "sortable": false,
    "name": "no_kta",
    "field": "no_kta"
  },
  {
    "label": "Tgl Daftar",
    "align": "left",
    "sortable": false,
    "name": "tgl_daftar",
    "field": "tgl_daftar"
  },
  {
    "label": "Jenis",
    "align": "left",
    "sortable": false,
    "name": "jenis",
    "field": "jenis"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Masa Berlaku",
    "align": "left",
    "sortable": false,
    "name": "masa_berlaku",
    "field": "masa_berlaku"
  },
  {
    "label": "Prrovinsi",
    "align": "left",
    "sortable": false,
    "name": "prrovinsi",
    "field": "prrovinsi"
  },
  {
    "label": "Kota",
    "align": "left",
    "sortable": false,
    "name": "kota",
    "field": "kota"
  },
  {
    "label": "Klub",
    "align": "left",
    "sortable": false,
    "name": "klub",
    "field": "klub"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  }
],
	ktaclaimslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Phone Number",
    "align": "left",
    "sortable": false,
    "name": "phone_number",
    "field": "phone_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Ktp Picture",
    "align": "left",
    "sortable": false,
    "name": "ktp_picture",
    "field": "ktp_picture"
  },
  {
    "label": "Status Claim",
    "align": "left",
    "sortable": false,
    "name": "status_claim",
    "field": "status_claim"
  },
  {
    "label": "Notes",
    "align": "left",
    "sortable": false,
    "name": "notes",
    "field": "notes"
  },
  {
    "label": "User Crm Id",
    "align": "left",
    "sortable": false,
    "name": "user_crm_id",
    "field": "user_crm_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "New Phone Number",
    "align": "left",
    "sortable": false,
    "name": "new_phone_number",
    "field": "new_phone_number"
  },
  {
    "label": "Claim At",
    "align": "left",
    "sortable": false,
    "name": "claim_at",
    "field": "claim_at"
  },
  {
    "label": "Jenis",
    "align": "left",
    "sortable": false,
    "name": "jenis",
    "field": "jenis"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "User Profile Id",
    "align": "left",
    "sortable": false,
    "name": "user_profile_id",
    "field": "user_profile_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	ktacrmlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "Iscetak",
    "align": "left",
    "sortable": false,
    "name": "iscetak",
    "field": "iscetak"
  },
  {
    "label": "Idnya",
    "align": "left",
    "sortable": false,
    "name": "idnya",
    "field": "idnya"
  },
  {
    "label": "Cetak Date",
    "align": "left",
    "sortable": false,
    "name": "cetak_date",
    "field": "cetak_date"
  },
  {
    "label": "Uid",
    "align": "left",
    "sortable": false,
    "name": "uid",
    "field": "uid"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Date Exp",
    "align": "left",
    "sortable": false,
    "name": "date_exp",
    "field": "date_exp"
  },
  {
    "label": "Namamember",
    "align": "left",
    "sortable": false,
    "name": "namamember",
    "field": "namamember"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Kis",
    "align": "left",
    "sortable": false,
    "name": "kis",
    "field": "kis"
  },
  {
    "label": "Kis Uid",
    "align": "left",
    "sortable": false,
    "name": "kis_uid",
    "field": "kis_uid"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Orders",
    "align": "left",
    "sortable": false,
    "name": "orders",
    "field": "orders"
  }
],
	ktacrmlistheader2: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Iscetak",
    "align": "left",
    "sortable": false,
    "name": "iscetak",
    "field": "iscetak"
  },
  {
    "label": "Idnya",
    "align": "left",
    "sortable": false,
    "name": "idnya",
    "field": "idnya"
  },
  {
    "label": "Cetak Date",
    "align": "left",
    "sortable": false,
    "name": "cetak_date",
    "field": "cetak_date"
  },
  {
    "label": "Uid",
    "align": "left",
    "sortable": false,
    "name": "uid",
    "field": "uid"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Date Exp",
    "align": "left",
    "sortable": false,
    "name": "date_exp",
    "field": "date_exp"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Club Role Id",
    "align": "left",
    "sortable": false,
    "name": "club_role_id",
    "field": "club_role_id"
  },
  {
    "label": "Kis Uid",
    "align": "left",
    "sortable": false,
    "name": "kis_uid",
    "field": "kis_uid"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Orders",
    "align": "left",
    "sortable": false,
    "name": "orders",
    "field": "orders"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "Namamember",
    "align": "left",
    "sortable": false,
    "name": "namamember",
    "field": "namamember"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Kis",
    "align": "left",
    "sortable": false,
    "name": "kis",
    "field": "kis"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  }
],
	lapmemberlistheader: [
  {
    "label": "Max",
    "align": "left",
    "sortable": false,
    "name": "max",
    "field": "max"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Tanggal Kta",
    "align": "left",
    "sortable": false,
    "name": "tanggal_kta",
    "field": "tanggal_kta"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Province Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_domicile",
    "field": "province_domicile"
  },
  {
    "label": "Status Kta",
    "align": "left",
    "sortable": false,
    "name": "status_kta",
    "field": "status_kta"
  },
  {
    "label": "Jenis Order",
    "align": "left",
    "sortable": false,
    "name": "jenis_order",
    "field": "jenis_order"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Verifikasi",
    "align": "left",
    "sortable": false,
    "name": "verifikasi",
    "field": "verifikasi"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  }
],
	listaccslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Grp Id",
    "align": "left",
    "sortable": false,
    "name": "grp_id",
    "field": "grp_id"
  },
  {
    "label": "Value",
    "align": "left",
    "sortable": false,
    "name": "value",
    "field": "value"
  },
  {
    "label": "Note",
    "align": "left",
    "sortable": false,
    "name": "note",
    "field": "note"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	logactivitieslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Crm Id",
    "align": "left",
    "sortable": false,
    "name": "user_crm_id",
    "field": "user_crm_id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	membershiporderslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Title",
    "align": "left",
    "sortable": false,
    "name": "payment_title",
    "field": "payment_title"
  },
  {
    "label": "Payment Description",
    "align": "left",
    "sortable": false,
    "name": "payment_description",
    "field": "payment_description"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Fee",
    "align": "left",
    "sortable": false,
    "name": "fee",
    "field": "fee"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "User Created",
    "align": "left",
    "sortable": false,
    "name": "user_created",
    "field": "user_created"
  },
  {
    "label": "User Crm Created",
    "align": "left",
    "sortable": false,
    "name": "user_crm_created",
    "field": "user_crm_created"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Payment Channel Id",
    "align": "left",
    "sortable": false,
    "name": "payment_channel_id",
    "field": "payment_channel_id"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Paid Info",
    "align": "left",
    "sortable": false,
    "name": "paid_info",
    "field": "paid_info"
  },
  {
    "label": "Bundling Id",
    "align": "left",
    "sortable": false,
    "name": "bundling_id",
    "field": "bundling_id"
  },
  {
    "label": "Ref Code",
    "align": "left",
    "sortable": false,
    "name": "ref_code",
    "field": "ref_code"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	membershiporderscopy1listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Title",
    "align": "left",
    "sortable": false,
    "name": "payment_title",
    "field": "payment_title"
  },
  {
    "label": "Payment Description",
    "align": "left",
    "sortable": false,
    "name": "payment_description",
    "field": "payment_description"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Fee",
    "align": "left",
    "sortable": false,
    "name": "fee",
    "field": "fee"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "User Created",
    "align": "left",
    "sortable": false,
    "name": "user_created",
    "field": "user_created"
  },
  {
    "label": "User Crm Created",
    "align": "left",
    "sortable": false,
    "name": "user_crm_created",
    "field": "user_crm_created"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Payment Channel Id",
    "align": "left",
    "sortable": false,
    "name": "payment_channel_id",
    "field": "payment_channel_id"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Paid Info",
    "align": "left",
    "sortable": false,
    "name": "paid_info",
    "field": "paid_info"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	membershipuserupdatelistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Expire Date",
    "align": "left",
    "sortable": false,
    "name": "expire_date",
    "field": "expire_date"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  }
],
	membershipuserslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	membershiporderslistheader2: [
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "User Created",
    "align": "left",
    "sortable": false,
    "name": "user_created",
    "field": "user_created"
  },
  {
    "label": "User Crm Created",
    "align": "left",
    "sortable": false,
    "name": "user_crm_created",
    "field": "user_crm_created"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Fee",
    "align": "left",
    "sortable": false,
    "name": "fee",
    "field": "fee"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Paid Info",
    "align": "left",
    "sortable": false,
    "name": "paid_info",
    "field": "paid_info"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Payment Title",
    "align": "left",
    "sortable": false,
    "name": "payment_title",
    "field": "payment_title"
  },
  {
    "label": "Payment Description",
    "align": "left",
    "sortable": false,
    "name": "payment_description",
    "field": "payment_description"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Payment Url",
    "align": "left",
    "sortable": false,
    "name": "payment_url",
    "field": "payment_url"
  },
  {
    "label": "Payment Channel Id",
    "align": "left",
    "sortable": false,
    "name": "payment_channel_id",
    "field": "payment_channel_id"
  },
  {
    "label": "Bundling Id",
    "align": "left",
    "sortable": false,
    "name": "bundling_id",
    "field": "bundling_id"
  },
  {
    "label": "Ref Code",
    "align": "left",
    "sortable": false,
    "name": "ref_code",
    "field": "ref_code"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	membershipuserslistheader2: [
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	notifslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Title",
    "align": "left",
    "sortable": false,
    "name": "title",
    "field": "title"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Read",
    "align": "left",
    "sortable": false,
    "name": "read",
    "field": "read"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Value",
    "align": "left",
    "sortable": false,
    "name": "value",
    "field": "value"
  },
  {
    "label": "Key",
    "align": "left",
    "sortable": false,
    "name": "key",
    "field": "key"
  },
  {
    "label": "Read At",
    "align": "left",
    "sortable": false,
    "name": "read_at",
    "field": "read_at"
  },
  {
    "label": "Icon",
    "align": "left",
    "sortable": false,
    "name": "icon",
    "field": "icon"
  },
  {
    "label": "Group",
    "align": "left",
    "sortable": false,
    "name": "group",
    "field": "group"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pkategorikendaraanlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pmerkkendaraanlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "P Kategori Kendaraan Id",
    "align": "left",
    "sortable": false,
    "name": "p_kategori_kendaraan_id",
    "field": "p_kategori_kendaraan_id"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pplatnomorlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Kode",
    "align": "left",
    "sortable": false,
    "name": "kode",
    "field": "kode"
  },
  {
    "label": "Wilayah",
    "align": "left",
    "sortable": false,
    "name": "wilayah",
    "field": "wilayah"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	ptipekendaraanlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "P Merk Kendaraan Id",
    "align": "left",
    "sortable": false,
    "name": "p_merk_kendaraan_id",
    "field": "p_merk_kendaraan_id"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	puserfavorpostlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Favor Type",
    "align": "left",
    "sortable": false,
    "name": "favor_type",
    "field": "favor_type"
  },
  {
    "label": "Favor Id",
    "align": "left",
    "sortable": false,
    "name": "favor_id",
    "field": "favor_id"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	partnershipbannerslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "File Banner",
    "align": "left",
    "sortable": false,
    "name": "file_banner",
    "field": "file_banner"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Home",
    "align": "left",
    "sortable": false,
    "name": "home",
    "field": "home"
  },
  {
    "label": "Detail",
    "align": "left",
    "sortable": false,
    "name": "detail",
    "field": "detail"
  },
  {
    "label": "Homepage1",
    "align": "left",
    "sortable": false,
    "name": "homepage1",
    "field": "homepage1"
  },
  {
    "label": "Homepage2",
    "align": "left",
    "sortable": false,
    "name": "homepage2",
    "field": "homepage2"
  },
  {
    "label": "Roda",
    "align": "left",
    "sortable": false,
    "name": "roda",
    "field": "roda"
  },
  {
    "label": "Tjh",
    "align": "left",
    "sortable": false,
    "name": "tjh",
    "field": "tjh"
  },
  {
    "label": "Radio",
    "align": "left",
    "sortable": false,
    "name": "radio",
    "field": "radio"
  },
  {
    "label": "Tv",
    "align": "left",
    "sortable": false,
    "name": "tv",
    "field": "tv"
  },
  {
    "label": "Psatu",
    "align": "left",
    "sortable": false,
    "name": "psatu",
    "field": "psatu"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	partnershipcategorieslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Icon",
    "align": "left",
    "sortable": false,
    "name": "icon",
    "field": "icon"
  },
  {
    "label": "Order",
    "align": "left",
    "sortable": false,
    "name": "order",
    "field": "order"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	partnershipctgbannerslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Partnership Category Id",
    "align": "left",
    "sortable": false,
    "name": "partnership_category_id",
    "field": "partnership_category_id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "File Banner",
    "align": "left",
    "sortable": false,
    "name": "file_banner",
    "field": "file_banner"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	partnerships20230913listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Partnership Category Id",
    "align": "left",
    "sortable": false,
    "name": "partnership_category_id",
    "field": "partnership_category_id"
  },
  {
    "label": "Front Banner",
    "align": "left",
    "sortable": false,
    "name": "front_banner",
    "field": "front_banner"
  },
  {
    "label": "Title",
    "align": "left",
    "sortable": false,
    "name": "title",
    "field": "title"
  },
  {
    "label": "Back Banner",
    "align": "left",
    "sortable": false,
    "name": "back_banner",
    "field": "back_banner"
  },
  {
    "label": "Diskon",
    "align": "left",
    "sortable": false,
    "name": "diskon",
    "field": "diskon"
  },
  {
    "label": "Front Desc",
    "align": "left",
    "sortable": false,
    "name": "front_desc",
    "field": "front_desc"
  },
  {
    "label": "Area",
    "align": "left",
    "sortable": false,
    "name": "area",
    "field": "area"
  },
  {
    "label": "Back Desc",
    "align": "left",
    "sortable": false,
    "name": "back_desc",
    "field": "back_desc"
  },
  {
    "label": "What You Get",
    "align": "left",
    "sortable": false,
    "name": "what_you_get",
    "field": "what_you_get"
  },
  {
    "label": "Redeem Instruction",
    "align": "left",
    "sortable": false,
    "name": "redeem_instruction",
    "field": "redeem_instruction"
  },
  {
    "label": "Redeem On",
    "align": "left",
    "sortable": false,
    "name": "redeem_on",
    "field": "redeem_on"
  },
  {
    "label": "Location",
    "align": "left",
    "sortable": false,
    "name": "location",
    "field": "location"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Update By",
    "align": "left",
    "sortable": false,
    "name": "update_by",
    "field": "update_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pkkendaraanslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Owner",
    "align": "left",
    "sortable": false,
    "name": "user_owner",
    "field": "user_owner"
  },
  {
    "label": "P Pulau Id",
    "align": "left",
    "sortable": false,
    "name": "p_pulau_id",
    "field": "p_pulau_id"
  },
  {
    "label": "Foto Kendaraan",
    "align": "left",
    "sortable": false,
    "name": "foto_kendaraan",
    "field": "foto_kendaraan"
  },
  {
    "label": "Tahun",
    "align": "left",
    "sortable": false,
    "name": "tahun",
    "field": "tahun"
  },
  {
    "label": "Nomor Polisi",
    "align": "left",
    "sortable": false,
    "name": "nomor_polisi",
    "field": "nomor_polisi"
  },
  {
    "label": "P Merk Kendaraan Id",
    "align": "left",
    "sortable": false,
    "name": "p_merk_kendaraan_id",
    "field": "p_merk_kendaraan_id"
  },
  {
    "label": "Tipe Kendaraan",
    "align": "left",
    "sortable": false,
    "name": "tipe_kendaraan",
    "field": "tipe_kendaraan"
  },
  {
    "label": "P Tipe Kendaraan Id",
    "align": "left",
    "sortable": false,
    "name": "p_tipe_kendaraan_id",
    "field": "p_tipe_kendaraan_id"
  },
  {
    "label": "Foto Kendaraan Belakang",
    "align": "left",
    "sortable": false,
    "name": "foto_kendaraan_belakang",
    "field": "foto_kendaraan_belakang"
  },
  {
    "label": "Foto Stnk",
    "align": "left",
    "sortable": false,
    "name": "foto_stnk",
    "field": "foto_stnk"
  },
  {
    "label": "P Plat Nomor Id",
    "align": "left",
    "sortable": false,
    "name": "p_plat_nomor_id",
    "field": "p_plat_nomor_id"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Order Id",
    "align": "left",
    "sortable": false,
    "name": "order_id",
    "field": "order_id"
  },
  {
    "label": "Kategori Kendaraan Id",
    "align": "left",
    "sortable": false,
    "name": "kategori_kendaraan_id",
    "field": "kategori_kendaraan_id"
  },
  {
    "label": "Foto Kiri Kendaraan",
    "align": "left",
    "sortable": false,
    "name": "foto_kiri_kendaraan",
    "field": "foto_kiri_kendaraan"
  },
  {
    "label": "Foto Kanan Kendaraan",
    "align": "left",
    "sortable": false,
    "name": "foto_kanan_kendaraan",
    "field": "foto_kanan_kendaraan"
  },
  {
    "label": "Foto Sim",
    "align": "left",
    "sortable": false,
    "name": "foto_sim",
    "field": "foto_sim"
  },
  {
    "label": "Nomor Mesin",
    "align": "left",
    "sortable": false,
    "name": "nomor_mesin",
    "field": "nomor_mesin"
  },
  {
    "label": "Nomor Rangka",
    "align": "left",
    "sortable": false,
    "name": "nomor_rangka",
    "field": "nomor_rangka"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pmorderlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Total",
    "align": "left",
    "sortable": false,
    "name": "total",
    "field": "total"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Ref Trx Id",
    "align": "left",
    "sortable": false,
    "name": "ref_trx_id",
    "field": "ref_trx_id"
  },
  {
    "label": "Ref Trx Response Status",
    "align": "left",
    "sortable": false,
    "name": "ref_trx_response_status",
    "field": "ref_trx_response_status"
  },
  {
    "label": "Ref Trx Response",
    "align": "left",
    "sortable": false,
    "name": "ref_trx_response",
    "field": "ref_trx_response"
  },
  {
    "label": "System Paid",
    "align": "left",
    "sortable": false,
    "name": "system_paid",
    "field": "system_paid"
  },
  {
    "label": "Processed Date",
    "align": "left",
    "sortable": false,
    "name": "processed_date",
    "field": "processed_date"
  },
  {
    "label": "Enrollment Type",
    "align": "left",
    "sortable": false,
    "name": "enrollment_type",
    "field": "enrollment_type"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	pmorderitemlistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Order Id",
    "align": "left",
    "sortable": false,
    "name": "order_id",
    "field": "order_id"
  },
  {
    "label": "Item Name",
    "align": "left",
    "sortable": false,
    "name": "item_name",
    "field": "item_name"
  },
  {
    "label": "Quantity",
    "align": "left",
    "sortable": false,
    "name": "quantity",
    "field": "quantity"
  },
  {
    "label": "Unit Price",
    "align": "left",
    "sortable": false,
    "name": "unit_price",
    "field": "unit_price"
  },
  {
    "label": "Total Price",
    "align": "left",
    "sortable": false,
    "name": "total_price",
    "field": "total_price"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Ref Id",
    "align": "left",
    "sortable": false,
    "name": "ref_id",
    "field": "ref_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Ref Id 2",
    "align": "left",
    "sortable": false,
    "name": "ref_id_2",
    "field": "ref_id_2"
  },
  {
    "label": "Enrollment Type",
    "align": "left",
    "sortable": false,
    "name": "enrollment_type",
    "field": "enrollment_type"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	productbannerslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Product Id",
    "align": "left",
    "sortable": false,
    "name": "product_id",
    "field": "product_id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "File Banner",
    "align": "left",
    "sortable": false,
    "name": "file_banner",
    "field": "file_banner"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	provincescopy20230913listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Island Id",
    "align": "left",
    "sortable": false,
    "name": "island_id",
    "field": "island_id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Club Id",
    "align": "left",
    "sortable": false,
    "name": "club_id",
    "field": "club_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Ketua",
    "align": "left",
    "sortable": false,
    "name": "ketua",
    "field": "ketua"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	refferallistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Ref Code",
    "align": "left",
    "sortable": false,
    "name": "ref_code",
    "field": "ref_code"
  },
  {
    "label": "Ref Name",
    "align": "left",
    "sortable": false,
    "name": "ref_name",
    "field": "ref_name"
  },
  {
    "label": "Ref Desc",
    "align": "left",
    "sortable": false,
    "name": "ref_desc",
    "field": "ref_desc"
  },
  {
    "label": "Ref Sales",
    "align": "left",
    "sortable": false,
    "name": "ref_sales",
    "field": "ref_sales"
  },
  {
    "label": "Ref Status",
    "align": "left",
    "sortable": false,
    "name": "ref_status",
    "field": "ref_status"
  },
  {
    "label": "Ref Date Start",
    "align": "left",
    "sortable": false,
    "name": "ref_date_start",
    "field": "ref_date_start"
  },
  {
    "label": "Ref Date End",
    "align": "left",
    "sortable": false,
    "name": "ref_date_end",
    "field": "ref_date_end"
  },
  {
    "label": "Created Date",
    "align": "left",
    "sortable": false,
    "name": "created_date",
    "field": "created_date"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "Modified By",
    "align": "left",
    "sortable": false,
    "name": "modified_by",
    "field": "modified_by"
  },
  {
    "label": "Ref Province",
    "align": "left",
    "sortable": false,
    "name": "ref_province",
    "field": "ref_province"
  },
  {
    "label": "Ref Fee",
    "align": "left",
    "sortable": false,
    "name": "ref_fee",
    "field": "ref_fee"
  },
  {
    "label": "Ref Quota",
    "align": "left",
    "sortable": false,
    "name": "ref_quota",
    "field": "ref_quota"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	reportaldylistheader: [
  {
    "label": "Max",
    "align": "left",
    "sortable": false,
    "name": "max",
    "field": "max"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Tanggal Kta",
    "align": "left",
    "sortable": false,
    "name": "tanggal_kta",
    "field": "tanggal_kta"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": true,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Province Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_domicile",
    "field": "province_domicile"
  },
  {
    "label": "Status Kta",
    "align": "left",
    "sortable": false,
    "name": "status_kta",
    "field": "status_kta"
  },
  {
    "label": "Jenis Order",
    "align": "left",
    "sortable": false,
    "name": "jenis_order",
    "field": "jenis_order"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Verifikasi",
    "align": "left",
    "sortable": false,
    "name": "verifikasi",
    "field": "verifikasi"
  }
],
	reportaldycopy1listheader: [
  {
    "label": "Max",
    "align": "left",
    "sortable": false,
    "name": "max",
    "field": "max"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Tanggal Kta",
    "align": "left",
    "sortable": false,
    "name": "tanggal_kta",
    "field": "tanggal_kta"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Province Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_domicile",
    "field": "province_domicile"
  },
  {
    "label": "Status Kta",
    "align": "left",
    "sortable": false,
    "name": "status_kta",
    "field": "status_kta"
  },
  {
    "label": "Jenis Order",
    "align": "left",
    "sortable": false,
    "name": "jenis_order",
    "field": "jenis_order"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  }
],
	reportaldylistheader2: [
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Tanggal Kta",
    "align": "left",
    "sortable": false,
    "name": "tanggal_kta",
    "field": "tanggal_kta"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Club Name",
    "align": "left",
    "sortable": true,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Paid At",
    "align": "left",
    "sortable": false,
    "name": "paid_at",
    "field": "paid_at"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Province Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_domicile",
    "field": "province_domicile"
  },
  {
    "label": "Status Kta",
    "align": "left",
    "sortable": false,
    "name": "status_kta",
    "field": "status_kta"
  },
  {
    "label": "Jenis Order",
    "align": "left",
    "sortable": false,
    "name": "jenis_order",
    "field": "jenis_order"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Amount",
    "align": "left",
    "sortable": false,
    "name": "amount",
    "field": "amount"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Verifikasi",
    "align": "left",
    "sortable": false,
    "name": "verifikasi",
    "field": "verifikasi"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Max",
    "align": "left",
    "sortable": false,
    "name": "max",
    "field": "max"
  }
],
	serviceorderslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Service Id",
    "align": "left",
    "sortable": false,
    "name": "service_id",
    "field": "service_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	servicevendorslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Service Id",
    "align": "left",
    "sortable": false,
    "name": "service_id",
    "field": "service_id"
  },
  {
    "label": "Vendor Id",
    "align": "left",
    "sortable": false,
    "name": "vendor_id",
    "field": "vendor_id"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	socialpostslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Community Id",
    "align": "left",
    "sortable": false,
    "name": "community_id",
    "field": "community_id"
  },
  {
    "label": "User Uuid",
    "align": "left",
    "sortable": false,
    "name": "user_uuid",
    "field": "user_uuid"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Text",
    "align": "left",
    "sortable": false,
    "name": "text",
    "field": "text"
  },
  {
    "label": "Text Color",
    "align": "left",
    "sortable": false,
    "name": "text_color",
    "field": "text_color"
  },
  {
    "label": "Description",
    "align": "left",
    "sortable": false,
    "name": "description",
    "field": "description"
  },
  {
    "label": "Background Color",
    "align": "left",
    "sortable": false,
    "name": "background_color",
    "field": "background_color"
  },
  {
    "label": "Background Image",
    "align": "left",
    "sortable": false,
    "name": "background_image",
    "field": "background_image"
  },
  {
    "label": "Video",
    "align": "left",
    "sortable": false,
    "name": "video",
    "field": "video"
  },
  {
    "label": "Link",
    "align": "left",
    "sortable": false,
    "name": "link",
    "field": "link"
  },
  {
    "label": "Privacy",
    "align": "left",
    "sortable": false,
    "name": "privacy",
    "field": "privacy"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "Like Number",
    "align": "left",
    "sortable": false,
    "name": "like_number",
    "field": "like_number"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Modified Date",
    "align": "left",
    "sortable": false,
    "name": "modified_date",
    "field": "modified_date"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	urllistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Value",
    "align": "left",
    "sortable": false,
    "name": "value",
    "field": "value"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Updated By",
    "align": "left",
    "sortable": false,
    "name": "updated_by",
    "field": "updated_by"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	useractivelistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  }
],
	usercardslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Taking Card",
    "align": "left",
    "sortable": false,
    "name": "taking_card",
    "field": "taking_card"
  },
  {
    "label": "Shipping Cost",
    "align": "left",
    "sortable": false,
    "name": "shipping_cost",
    "field": "shipping_cost"
  },
  {
    "label": "Delivery Service",
    "align": "left",
    "sortable": false,
    "name": "delivery_service",
    "field": "delivery_service"
  },
  {
    "label": "Resi No",
    "align": "left",
    "sortable": false,
    "name": "resi_no",
    "field": "resi_no"
  },
  {
    "label": "Download Count",
    "align": "left",
    "sortable": false,
    "name": "download_count",
    "field": "download_count"
  },
  {
    "label": "Print Count",
    "align": "left",
    "sortable": false,
    "name": "print_count",
    "field": "print_count"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Membership Order Id",
    "align": "left",
    "sortable": false,
    "name": "membership_order_id",
    "field": "membership_order_id"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	usercrmroleslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Created By",
    "align": "left",
    "sortable": false,
    "name": "created_by",
    "field": "created_by"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Is Delete",
    "align": "left",
    "sortable": false,
    "name": "is_delete",
    "field": "is_delete"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	usercrmslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Role",
    "align": "left",
    "sortable": false,
    "name": "role",
    "field": "role"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Edc",
    "align": "left",
    "sortable": false,
    "name": "edc",
    "field": "edc"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userktaalllistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Membership Id",
    "align": "left",
    "sortable": false,
    "name": "membership_id",
    "field": "membership_id"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  }
],
	userotpslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Type",
    "align": "left",
    "sortable": false,
    "name": "type",
    "field": "type"
  },
  {
    "label": "Otp For",
    "align": "left",
    "sortable": false,
    "name": "otp_for",
    "field": "otp_for"
  },
  {
    "label": "Otp Code",
    "align": "left",
    "sortable": false,
    "name": "otp_code",
    "field": "otp_code"
  },
  {
    "label": "Expired At",
    "align": "left",
    "sortable": false,
    "name": "expired_at",
    "field": "expired_at"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userprofileslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "Ref Code",
    "align": "left",
    "sortable": false,
    "name": "ref_code",
    "field": "ref_code"
  },
  {
    "label": "Rhesus",
    "align": "left",
    "sortable": false,
    "name": "rhesus",
    "field": "rhesus"
  },
  {
    "label": "Verifikasi",
    "align": "left",
    "sortable": false,
    "name": "verifikasi",
    "field": "verifikasi"
  },
  {
    "label": "Temp Club",
    "align": "left",
    "sortable": false,
    "name": "temp_club",
    "field": "temp_club"
  },
  {
    "label": "Confirmnik",
    "align": "left",
    "sortable": false,
    "name": "confirmnik",
    "field": "confirmnik"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	gender: [    
{value: "Male", label: "Male"},
	{value: "Female", label: "Female"}
    ],
	userprofiles20230601listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userprofiles20231115listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "Ref Code",
    "align": "left",
    "sortable": false,
    "name": "ref_code",
    "field": "ref_code"
  },
  {
    "label": "Rhesus",
    "align": "left",
    "sortable": false,
    "name": "rhesus",
    "field": "rhesus"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	usersimslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Sim Type",
    "align": "left",
    "sortable": false,
    "name": "sim_type",
    "field": "sim_type"
  },
  {
    "label": "Sim Number",
    "align": "left",
    "sortable": false,
    "name": "sim_number",
    "field": "sim_number"
  },
  {
    "label": "Sim Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_photo",
    "field": "sim_photo"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userprofileslistheader2: [
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Member Since",
    "align": "left",
    "sortable": false,
    "name": "member_since",
    "field": "member_since"
  },
  {
    "label": "Province Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "province_id_domicile",
    "field": "province_id_domicile"
  },
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Province Id",
    "align": "left",
    "sortable": false,
    "name": "province_id",
    "field": "province_id"
  },
  {
    "label": "City Id",
    "align": "left",
    "sortable": false,
    "name": "city_id",
    "field": "city_id"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Address",
    "align": "left",
    "sortable": false,
    "name": "address",
    "field": "address"
  },
  {
    "label": "Rt Rw",
    "align": "left",
    "sortable": false,
    "name": "rt_rw",
    "field": "rt_rw"
  },
  {
    "label": "Ward Id",
    "align": "left",
    "sortable": false,
    "name": "ward_id",
    "field": "ward_id"
  },
  {
    "label": "Zip Code",
    "align": "left",
    "sortable": false,
    "name": "zip_code",
    "field": "zip_code"
  },
  {
    "label": "Address Domicile",
    "align": "left",
    "sortable": false,
    "name": "address_domicile",
    "field": "address_domicile"
  },
  {
    "label": "Rt Rw Domicile",
    "align": "left",
    "sortable": false,
    "name": "rt_rw_domicile",
    "field": "rt_rw_domicile"
  },
  {
    "label": "City Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "city_id_domicile",
    "field": "city_id_domicile"
  },
  {
    "label": "District Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "district_id_domicile",
    "field": "district_id_domicile"
  },
  {
    "label": "Ward Id Domicile",
    "align": "left",
    "sortable": false,
    "name": "ward_id_domicile",
    "field": "ward_id_domicile"
  },
  {
    "label": "Zip Code Domicile",
    "align": "left",
    "sortable": false,
    "name": "zip_code_domicile",
    "field": "zip_code_domicile"
  },
  {
    "label": "Identifier Card",
    "align": "left",
    "sortable": false,
    "name": "identifier_card",
    "field": "identifier_card"
  },
  {
    "label": "Identifier Number",
    "align": "left",
    "sortable": false,
    "name": "identifier_number",
    "field": "identifier_number"
  },
  {
    "label": "Identifier Photo",
    "align": "left",
    "sortable": false,
    "name": "identifier_photo",
    "field": "identifier_photo"
  },
  {
    "label": "Photo Profile",
    "align": "left",
    "sortable": false,
    "name": "photo_profile",
    "field": "photo_profile"
  },
  {
    "label": "Family Card Photo",
    "align": "left",
    "sortable": false,
    "name": "family_card_photo",
    "field": "family_card_photo"
  },
  {
    "label": "Sim Car Number",
    "align": "left",
    "sortable": false,
    "name": "sim_car_number",
    "field": "sim_car_number"
  },
  {
    "label": "Sim Car Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_car_photo",
    "field": "sim_car_photo"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Old Kta Number",
    "align": "left",
    "sortable": false,
    "name": "old_kta_number",
    "field": "old_kta_number"
  },
  {
    "label": "Hobbies",
    "align": "left",
    "sortable": false,
    "name": "hobbies",
    "field": "hobbies"
  },
  {
    "label": "Sim Motor Number",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_number",
    "field": "sim_motor_number"
  },
  {
    "label": "Sim Motor Photo",
    "align": "left",
    "sortable": false,
    "name": "sim_motor_photo",
    "field": "sim_motor_photo"
  },
  {
    "label": "Kis Signature Photo",
    "align": "left",
    "sortable": false,
    "name": "kis_signature_photo",
    "field": "kis_signature_photo"
  },
  {
    "label": "Kis Parents Doc",
    "align": "left",
    "sortable": false,
    "name": "kis_parents_doc",
    "field": "kis_parents_doc"
  },
  {
    "label": "Kis Adrt",
    "align": "left",
    "sortable": false,
    "name": "kis_adrt",
    "field": "kis_adrt"
  },
  {
    "label": "Email",
    "align": "left",
    "sortable": false,
    "name": "email",
    "field": "email"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Gender",
    "align": "left",
    "sortable": false,
    "name": "gender",
    "field": "gender"
  },
  {
    "label": "Blood Type",
    "align": "left",
    "sortable": false,
    "name": "blood_type",
    "field": "blood_type"
  },
  {
    "label": "Nationality",
    "align": "left",
    "sortable": false,
    "name": "nationality",
    "field": "nationality"
  },
  {
    "label": "Birthday",
    "align": "left",
    "sortable": false,
    "name": "birthday",
    "field": "birthday"
  },
  {
    "label": "Birth Place",
    "align": "left",
    "sortable": false,
    "name": "birth_place",
    "field": "birth_place"
  },
  {
    "label": "Age Group",
    "align": "left",
    "sortable": false,
    "name": "age_group",
    "field": "age_group"
  },
  {
    "label": "Ref Code",
    "align": "left",
    "sortable": false,
    "name": "ref_code",
    "field": "ref_code"
  },
  {
    "label": "Rhesus",
    "align": "left",
    "sortable": false,
    "name": "rhesus",
    "field": "rhesus"
  },
  {
    "label": "Verifikasi",
    "align": "left",
    "sortable": false,
    "name": "verifikasi",
    "field": "verifikasi"
  },
  {
    "label": "Temp Club",
    "align": "left",
    "sortable": false,
    "name": "temp_club",
    "field": "temp_club"
  },
  {
    "label": "Confirmnik",
    "align": "left",
    "sortable": false,
    "name": "confirmnik",
    "field": "confirmnik"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	userslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Device Token",
    "align": "left",
    "sortable": false,
    "name": "device_token",
    "field": "device_token"
  },
  {
    "label": "Remember Token",
    "align": "left",
    "sortable": false,
    "name": "remember_token",
    "field": "remember_token"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	users20230601listheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Remember Token",
    "align": "left",
    "sortable": false,
    "name": "remember_token",
    "field": "remember_token"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Device Token",
    "align": "left",
    "sortable": false,
    "name": "device_token",
    "field": "device_token"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	vclubstatuslistheader: [
  {
    "label": "Club Name",
    "align": "left",
    "sortable": false,
    "name": "club_name",
    "field": "club_name"
  },
  {
    "label": "Club Type",
    "align": "left",
    "sortable": false,
    "name": "club_type",
    "field": "club_type"
  },
  {
    "label": "Club Code",
    "align": "left",
    "sortable": false,
    "name": "club_code",
    "field": "club_code"
  },
  {
    "label": "Package Status",
    "align": "left",
    "sortable": false,
    "name": "package_status",
    "field": "package_status"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Province",
    "align": "left",
    "sortable": false,
    "name": "province",
    "field": "province"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  }
],
	vdaerahlistheader: [
  {
    "label": "Propid",
    "align": "left",
    "sortable": false,
    "name": "propid",
    "field": "propid"
  },
  {
    "label": "Prop",
    "align": "left",
    "sortable": false,
    "name": "prop",
    "field": "prop"
  },
  {
    "label": "Kotaid",
    "align": "left",
    "sortable": false,
    "name": "kotaid",
    "field": "kotaid"
  },
  {
    "label": "Kota Kab",
    "align": "left",
    "sortable": false,
    "name": "kota_kab",
    "field": "kota_kab"
  },
  {
    "label": "Kecid",
    "align": "left",
    "sortable": false,
    "name": "kecid",
    "field": "kecid"
  },
  {
    "label": "Kec",
    "align": "left",
    "sortable": false,
    "name": "kec",
    "field": "kec"
  },
  {
    "label": "Kelid",
    "align": "left",
    "sortable": false,
    "name": "kelid",
    "field": "kelid"
  },
  {
    "label": "Kel",
    "align": "left",
    "sortable": false,
    "name": "kel",
    "field": "kel"
  }
],
	vkisstatuslistheader: [
  {
    "label": "User Id",
    "align": "left",
    "sortable": false,
    "name": "user_id",
    "field": "user_id"
  },
  {
    "label": "Enrolled Date",
    "align": "left",
    "sortable": false,
    "name": "enrolled_date",
    "field": "enrolled_date"
  },
  {
    "label": "Price",
    "align": "left",
    "sortable": false,
    "name": "price",
    "field": "price"
  },
  {
    "label": "Order Number",
    "align": "left",
    "sortable": false,
    "name": "order_number",
    "field": "order_number"
  },
  {
    "label": "Payment Id",
    "align": "left",
    "sortable": false,
    "name": "payment_id",
    "field": "payment_id"
  },
  {
    "label": "Phone Number",
    "align": "left",
    "sortable": false,
    "name": "phone_number",
    "field": "phone_number"
  },
  {
    "label": "Nama Customer",
    "align": "left",
    "sortable": false,
    "name": "nama_customer",
    "field": "nama_customer"
  },
  {
    "label": "Provinsi",
    "align": "left",
    "sortable": false,
    "name": "provinsi",
    "field": "provinsi"
  },
  {
    "label": "Kis Province Id",
    "align": "left",
    "sortable": false,
    "name": "kis_province_id",
    "field": "kis_province_id"
  },
  {
    "label": "Kode Kis",
    "align": "left",
    "sortable": false,
    "name": "kode_kis",
    "field": "kode_kis"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  },
  {
    "label": "Masa Berlaku Kta",
    "align": "left",
    "sortable": false,
    "name": "masa_berlaku_kta",
    "field": "masa_berlaku_kta"
  }
],
	vktastatuslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Uuid",
    "align": "left",
    "sortable": false,
    "name": "uuid",
    "field": "uuid"
  },
  {
    "label": "Username",
    "align": "left",
    "sortable": false,
    "name": "username",
    "field": "username"
  },
  {
    "label": "Full Name",
    "align": "left",
    "sortable": false,
    "name": "full_name",
    "field": "full_name"
  },
  {
    "label": "Expired Date",
    "align": "left",
    "sortable": false,
    "name": "expired_date",
    "field": "expired_date"
  },
  {
    "label": "Status",
    "align": "left",
    "sortable": false,
    "name": "status",
    "field": "status"
  },
  {
    "label": "Kta Number",
    "align": "left",
    "sortable": false,
    "name": "kta_number",
    "field": "kta_number"
  }
],
	vreportcetaktktlistheader: [
  {
    "label": "Province",
    "align": "left",
    "sortable": false,
    "name": "province",
    "field": "province"
  },
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Star",
    "align": "left",
    "sortable": false,
    "name": "star",
    "field": "star"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "Cetak At",
    "align": "left",
    "sortable": false,
    "name": "cetak_at",
    "field": "cetak_at"
  },
  {
    "label": "Activated At",
    "align": "left",
    "sortable": false,
    "name": "activated_at",
    "field": "activated_at"
  }
],
	wardslistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "District Id",
    "align": "left",
    "sortable": false,
    "name": "district_id",
    "field": "district_id"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Name",
    "align": "left",
    "sortable": false,
    "name": "name",
    "field": "name"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],
	welcomebannerlistheader: [
  {
    "label": "Deskripsi",
    "align": "left",
    "sortable": false,
    "name": "deskripsi",
    "field": "deskripsi"
  },
  {
    "label": "Gambar",
    "align": "left",
    "sortable": false,
    "name": "gambar",
    "field": "gambar"
  },
  {
    "label": "Tipe",
    "align": "left",
    "sortable": false,
    "name": "tipe",
    "field": "tipe"
  }
],
	welcomeberitalistheader: [
  {
    "label": "Id",
    "align": "left",
    "sortable": false,
    "name": "id",
    "field": "id"
  },
  {
    "label": "Judul",
    "align": "left",
    "sortable": false,
    "name": "judul",
    "field": "judul"
  },
  {
    "label": "Deskripsi",
    "align": "left",
    "sortable": false,
    "name": "deskripsi",
    "field": "deskripsi"
  },
  {
    "label": "Kategori",
    "align": "left",
    "sortable": false,
    "name": "kategori",
    "field": "kategori"
  },
  {
    "label": "User Created",
    "align": "left",
    "sortable": false,
    "name": "user_created",
    "field": "user_created"
  },
  {
    "label": "User Updated",
    "align": "left",
    "sortable": false,
    "name": "user_updated",
    "field": "user_updated"
  },
  {
    "label": "Created At",
    "align": "left",
    "sortable": false,
    "name": "created_at",
    "field": "created_at"
  },
  {
    "label": "Updated At",
    "align": "left",
    "sortable": false,
    "name": "updated_at",
    "field": "updated_at"
  },
  {
    "label": "Active",
    "align": "left",
    "sortable": false,
    "name": "active",
    "field": "active"
  },
  {
    "label": "Gambar",
    "align": "left",
    "sortable": false,
    "name": "gambar",
    "field": "gambar"
  },
  {
    "label": "",
    "align": "right",
    "sortable": false,
    "name": "btnactions",
    "field": ""
  }
],

    exportFormats: {
        print: {
			label: 'Print',
			color: 'blue',
            icon: 'print',
            id: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			color: 'red',
            icon: 'picture_as_pdf',
            id: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
			color: 'green',
            icon: 'grid_on',
            id: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
			color: 'teal',
            icon: 'grid_on',
            id: 'csv',
            ext: 'csv',
        },
    },
    
}